import axios from "axios";
import Url from "./Url";
import React, { useEffect, useState } from "react";

const MessageContact = (props) => {
  const url = <Url />;
  const d = new Date(props.showMessageContact.createdAt);

  const [boxDelete, setBoxDelete] = useState("");
  const [stateBox, setStateBox] = useState("");
  const [x, setX] = useState(false);
  const [showMC, setShowMC] = useState(false);

  const resetBoxDelete = () => {
    setX(false);
  };

  const askDeleteUser = (id) => {
    setStateBox(id);
    setX(!x);

    setBoxDelete(
      <div className="boxDelContactMessage">
        <div>
          Confimer la suppression du message de :{" "}
          {props.showMessageContact.firstname +
            " " +
            props.showMessageContact.name}
        </div>
        <div className="choixDel">
          <p className="confirmDelete" onClick={() => deleteMessageContact(id)}>
            OUI
          </p>
          <p className="annuleDelete" onClick={() => resetBoxDelete()}>
            NON
          </p>
        </div>
      </div>
    );
  };

  const deleteMessageContact = (id) => {
    axios
      .delete(url.type + `contact/${id}`)
      .then(() => window.location.reload());
  };

  const [stateMess, setStateMess] = useState(false);

  ///essai compteur ///
  const [ww, setWW] = useState([]);
  useEffect(() => {
    axios
      .get(url.type + "contact")

      .then((res) => {
        setWW(
          res.data.filter((k) => k.state === false).map((k) => k.id).length
        );
      });

    axios
      .get(url.type + `contact/${props.showMessageContact.id}`)
      .then((res) => setStateMess(res.data.state));
  }, [showMC]);

  return (
    <li>
      <div
        id="entete-contact"
        onClick={() => {
          setShowMC(!showMC);
          axios.put(url.type + `contact/${props.showMessageContact.id}`, {
            state: true,
          });
        }}
      >
        <div id="messNL" className={!stateMess ? "messNL" : ""}>
          {/* {!stateMess && <i class="fa-solid fa-circle"></i>} */}

          <div>
            <h3>
              Message de {props.showMessageContact.firstname}{" "}
              {props.showMessageContact.name}
            </h3>
            <h4>
              Reçu le : {d.toLocaleDateString()} à {d.toLocaleTimeString()}
            </h4>
          </div>
        </div>
      </div>
      {showMC && (
        <div className="messageContact-deleteMessageContact">
          <div className="infoMessageContact">
            <p>{"Nom : " + props.showMessageContact.name}</p>
            <p>{"Prénom : " + props.showMessageContact.firstname}</p>
            <p>{"Email : " + props.showMessageContact.email}</p>
            <p>{"Tel : " + props.showMessageContact.tel}</p>
            <p>{"Code postal : " + props.showMessageContact.codepostal}</p>
            <p>{"Adresse : " + props.showMessageContact.adresse}</p>
            <p>{"Ville : " + props.showMessageContact.ville}</p>
            <p>{"Profession : " + props.showMessageContact.profession}</p>
            <p>{"Sujet : " + props.showMessageContact.sujet}</p>
            <br />
            <p id="messageContent">{props.showMessageContact.message}</p>

            <button onClick={() => askDeleteUser(props.showMessageContact.id)}>
              Supprimer
            </button>
          </div>

          {props.showMessageContact.id === stateBox && x && boxDelete}
        </div>
      )}
    </li>
  );
};

export default MessageContact;
