import axios from "axios";
import React from "react";
import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Up from "../components/Up";
import Background from "../components/Background";
import Url from "../components/Url";
import { useForm } from "react-hook-form";
import NavConnexion from "../components/NavConnexion";
import MessageContact from "../components/MessageContact";
import MessageAdhesion from "../components/MessageAdhesion";
import Gestion from "./Gestion";
import Title from "../components/Title";
import Footer from "../components/Footer";
import StateCommande from "../components/StateCommande";

const Perso = () => {
  const url = <Url />;
  const [cookies, setCookie, removeCookie] = useCookies([
    "token",
    "isLog",
    "id",
    "theme",
  ]);
  const [name, setName] = useState();
  const [photoAvatar, setPhotoAvatar] = useState("");
  const [infoAvatar, setInfoAvatar] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [containMessageContact, setContainMessageContact] = useState([]);
  const [acg, setAcg] = useState("gestion");

  //const [adhesionContact, setAdhesionContact] = useState("adhesion");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleChange = (e) => {
    setCookie("theme", e.target.value, { path: "/", sameSite: "strict" });
    //navigate("/", { replace: true });
    window.location.reload();
  };

  useEffect(() => {
    axios
      .get(url.type + `auth/${cookies.id}`)
      .then((res) => {
        setPhotoAvatar(res.data.avatar);
        setName(res.data.username);
        setIsAdmin(res.data.isAdmin);
      })
      .catch((err) => {
        console.log("err.response.data.error");
      });
  }, [cookies.id]);

  const onSubmit = (donnees) => {
    const formData = new FormData();
    formData.append("image", donnees.avatar[0]);
    axios
      .put(url.type + `auth/${cookies.id}`, formData)
      .then((res) => {
        setInfoAvatar("Avatar changé ^^");
        function x() {
          window.location.reload();
        }
        setTimeout(x, 2000);
      })
      .catch((err) => {
        console.log(err.response.data.error);
      });
  };

  useEffect(() => {
    axios
      .get(url.type + "contact")
      .then((res) => setContainMessageContact(res.data));
  }, []);

  const [containMessageAdhesion, setContainMessageAdhesion] = useState([]);
  useEffect(() => {
    axios
      .get(url.type + "adhesion")
      .then((res) => setContainMessageAdhesion(res.data));
  }, []);

  const [pdfUserData, setPdfUserData] = useState();

  useEffect(() => {
    axios.get(url.type + "pdfUser").then((res) => setPdfUserData(res.data));
  }, []);

  const [ww, setWW] = useState([]);
  const [xx, setXX] = useState([]);
  const [actualise, setActualise] = useState(false);

  useEffect(() => {
    axios.get(url.type + "contact").then((res) => {
      setWW(res.data.filter((k) => k.state === false).map((k) => k.id).length);
    });
    axios.get(url.type + "adhesion").then((res) => {
      setXX(res.data.filter((k) => k.state === false).map((k) => k.id).length);
    });
  }, [ww, actualise, xx]);

  return (
    <div>
      <Up />
      <header>
        <div className="headerTwo">
          <NavLink to="/" className="back">
            <i className="fa-solid fa-arrow-left"></i>
          </NavLink>
          <span>Paramètres</span>
        </div>
        <nav>
          <NavConnexion />
        </nav>
        <Background />
      </header>

      <div id="menu-gestion">
        <div id="avatar-theme">
          <div className="changePhoto">
            <p>CHANGER AVATAR : </p>
            <div id="photoProfil">
              <label htmlFor="fileProfil">
                {" "}
                <img src={photoAvatar} alt="aperçu" />
              </label>
              <input
                type="file"
                id="fileProfil"
                name="avatar"
                {...register("avatar")}
              />
              <button type="submit" onClick={handleSubmit(onSubmit)}>
                Poster
              </button>
            </div>
            {infoAvatar}
          </div>

          <div className="changeTheme">
            <p>MODIFIER LE THEME :</p>
            <select
              defaultValue={cookies.theme}
              name="changeTheme"
              id="changeTheme"
              onChange={handleChange}
            >
              <option value="original">Original</option>
              {/* <option value="dark">Dark</option> */}
              <option value="noel">Noël</option>
            </select>
          </div>
        </div>
        <div id="separation"></div>
        {isAdmin && (
          <div id="choixADCG">
            <button
              className={acg === "gestion" ? "btn-gestion-active" : ""}
              id="btn-parametre"
              onClick={() => setAcg("gestion")}
            >
              GESTION
            </button>
            <button
              className={acg === "adhesion" ? "btn-gestion-active" : ""}
              id="btn-parametre"
              onClick={() => {
                setAcg("adhesion");
              }}
            >
              ADHESION
              {xx ? <span>{xx}</span> : ""}
            </button>
            <button
              className={acg === "contact" ? "btn-gestion-active" : ""}
              id="btn-parametre"
              onClick={() => setAcg("contact")}
            >
              CONTACT
              {ww ? <span>{ww}</span> : ""}
            </button>
            <button
              className={acg === "commande" ? "btn-gestion-active" : ""}
              id="btn-parametre"
              onClick={() => setAcg("commande")}
            >
              COMMANDE
            </button>
          </div>
        )}
      </div>

      {isAdmin && acg === "adhesion" && (
        <div id="messageAdhesion">
          <ul className="messageContactAdhesion">
            {containMessageAdhesion.map((showMessageAdhesion, index) => (
              <MessageAdhesion
                showMessageAdhesion={showMessageAdhesion}
                index={index}
              />
            ))}
          </ul>
        </div>
      )}

      {isAdmin && acg === "contact" && (
        <div id="messageContact">
          <ul className="messageContactAdhesion">
            {containMessageContact.map((showMessageContact, index) => (
              <div
                onClick={() => {
                  setActualise(!actualise);
                }}
              >
                <MessageContact
                  showMessageContact={showMessageContact}
                  index={index}
                  ww={ww}
                />
              </div>
            ))}
          </ul>
        </div>
      )}

      {isAdmin && acg === "gestion" && <Gestion />}

      {isAdmin && acg === "commande" && (
        <div id="afficheCommande">
          <ul>
            {pdfUserData.map((cmd, index) => (
              <StateCommande cmd={cmd} index={index} />
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Perso;
