import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import NavConnexion from "./NavConnexion";
import { HashLink } from "react-router-hash-link";

const Navigation = () => {
  const [cookies, setCookie] = useCookies(["token", "isLog", "id"]);

  // axios.defaults.headers.common["Authorization"] = "Bearer " + cookies.token;

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 150) {
        setCount(false);
      }
    });
  }, []);

  const big = (
    <>
      <img src="./images/logo.png" alt="logoSEM" id="logo" />
      <div className="accueilV">
        <NavLink
          to="/"
          id="accueil"
          className={(nav) => (nav.isActive ? "nav-active" : "")}
        >
          <span>
            {/* <i class="fa-solid fa-house"></i> */}
            Accueil
            {/* <i id="i" className="fa-sharp fa-solid fa-sort-down"></i>  */}
          </span>
          <div className="borderNav"></div>
        </NavLink>
        {/*<div className="menuHome" id="aaa">
          <NavLink to="/">Accueil</NavLink>

          <HashLink smooth to="/#historique">
            Historique
          </HashLink>
          <HashLink smooth to="/#administration">
            Administration
          </HashLink>

          <NavLink to="/">Le site</NavLink>
  </div>*/}
      </div>

      <div className="activityV">
        <NavLink
          to="/activity"
          id="activity"
          className={(nav) => (nav.isActive ? "nav-active" : "")}
        >
          <span>
            {" "}
            Activités
            <i id="i" className="fa-sharp fa-solid fa-sort-down"></i>
          </span>
          <div className="borderNav"></div>
        </NavLink>
        <div className="menuHome" id="ccc">
          <HashLink smooth to="/activity#communication">
            Communication
          </HashLink>
          <HashLink smooth to="/activity#journeeEtudes">
            Journée d'études
          </HashLink>
          <HashLink smooth to="/activity#chroniques">
            Chroniques
          </HashLink>
          <HashLink smooth to="/activity#achatsMusee">
            Achats pour les musées
          </HashLink>
          <HashLink smooth to="/activity#museeVirtuel">
            Musée virtuel
          </HashLink>
          <HashLink smooth to="/activity#nosParticipations">
            Nos participations
          </HashLink>
        </div>
      </div>

      <NavLink
        to="/adhesion"
        id="adhesion"
        className={(nav) => (nav.isActive ? "nav-active" : "")}
      >
        <span>Adhésion</span>
        <div className="borderNav"></div>
      </NavLink>
      <div className="paysV">
        <NavLink
          to="/pays"
          id="pays"
          className={(nav) => (nav.isActive ? "nav-active" : "")}
        >
          <span>
            {" "}
            Le Pays de Montbéliard
            <i id="i" className="fa-sharp fa-solid fa-sort-down"></i>
          </span>
          <div className="borderNav"></div>
        </NavLink>
        <div className="menuHome" id="bbb">
          <HashLink smooth to="/pays#terreWurt">
            Une terre Wurtembergeoise
          </HashLink>
          <HashLink smooth to="/pays#terreMarq">
            Une terre marquée ...
          </HashLink>
          <HashLink smooth to="/pays#terreProf">
            Une terre profondément marquée
          </HashLink>
          <HashLink smooth to="/pays#terreAud">
            Une terre d'audace et d'innovation
          </HashLink>
        </div>
      </div>

      <NavLink
        to="/contact"
        className={(nav) => (nav.isActive ? "nav-active" : "")}
        id="contact"
      >
        <span>Contact</span>
        <div className="borderNav"></div>
      </NavLink>
    </>
  );

  const [navP, setNavP] = useState(big);

  const [count, setCount] = useState(false);
  const [menu, setMenu] = useState("fa-solid fa-bars");
  const [classNavCol, setClassNavCol] = useState("navColHide");

  useEffect(() => {
    count ? setNavP(big) : setNavP(big);

    if (!count) {
      setMenu("fa-solid fa-bars");
      setClassNavCol("navColHide");
    } else {
      setClassNavCol("navColShow");
      setMenu("fa-solid fa-xmark");
    }
  }, [count]);
  const [v, setV] = useState(true);
  const toggleNav = (
    <div className="navS">
      <img src="./images/logo.png" alt="logoSEM" className="logoS" />
      <i
        className={menu}
        onClick={() => {
          setCount(!count);
          setV(false);
        }}
      ></i>
    </div>
  );

  const [matches, setMatches] = React.useState(true);
  React.useEffect(() => {
    const matchQueryList = window.matchMedia("(max-width: 980px");
    const handleChange = (e) => {
      setMatches(e.matches);
      if (e.matches) {
        setNavP("");
      } else {
        setNavP(big);
        setCount(false);
      }
    };
    matchQueryList.addEventListener("change", handleChange);
  });

  return (
    <>
      <nav>
        <div className="toggleNav">
          {toggleNav}
          <div className={v ? "navColStart" : classNavCol}>
            {navP}
            {/*count ? navP : ""*/}
          </div>
        </div>
        <div id="navPrincipal">{big}</div>

        <NavConnexion />
      </nav>
    </>
  );
};

export default Navigation;
