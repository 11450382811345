import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Up from "../components/Up";

const Societes = () => {
  return (
    <div>
      <Up />
      <Header />

      <div className="societes">
        <h2 className="communication">Sociétés correspondantes</h2>

        <div className="socFC">
          <h3>Franche-Comté</h3>
          <a href="https://www.emulationdoubs.fr/">
            Société d'émulation du Doubs; Histoire et Patrimoine comtois (25)
          </a>
          <a href="">
            Académie des Sciences, Belles Lettres et Arts de Besançon et de
            Franche-Comté (25)
          </a>
          <a href="">
            Société d'Histoire Naturelle du Pays de Montbéliard (25)
          </a>
          <a href="">
            Folklore comtois, Musée de plein air des maisons comtoise
          </a>
          <a href="">Centre d'Entraide Généalogique de Franche-Comté (25)</a>
          <a href="">
            Haute-Saône SALSA ; Patrimoine, Histoire, Généalogie (70)
          </a>
          <a href="">Société Belfortaine d'émulation (90)</a>
          <a href="">Société d'émulation du Jura (39)</a>
          <a href="">Association Franche Bourgogne (70)</a>
          <a href="">
            Société d'Histoire et d'Archéologie de l'arrondissement de Lure (70)
          </a>
          <a href="">La Vôge</a>
        </div>
        <div className="socBourgogne">
          <h3>Bourgogne</h3>
          <a href="">Société d'Histoire et d'Archéologie de Beaune (21)</a>
          <a href="">
            Société de l'Académie des Sciences, Arts et Belles Lettres (21)
          </a>
          <a href="">
            Société d'Histoire et d'Archéologie de Chalon sur Saône (71)
          </a>
        </div>
        <div className="socAlsace">
          <h3>Alsace</h3>
          <a href="">
            Société d'Histoire et d'Archéologie de Saverne et des Environs (67)
          </a>
          <a href="">Société d'Histoire du Sungau (68)</a>
          <a href="">
            Société d'Histoire et de Géographie de Mulhouse : Annuaire
            historique de Mulhouse
          </a>
        </div>
        <div className="socAutres">
          <h3>Autres régions</h3>
          <a href="">
            Association Française des Amateurs d'Horlogerie Ancienne
          </a>
          <a href="">Académie de Stanislas (Nancy)</a>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Societes;
