import React, { useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";
import { NavLink } from "react-router-dom";

const NavPays = () => {
  const [show, handleShow] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 150) {
        handleShow(true);
      } else handleShow(false);
    });
  }, []);
  return (
    <>
      {show && (
        <div id="navPays">
          <span>
            <div id="pPays">
              <p>
                Le Pays de Montbéliard{" "}
                <i className="fa-sharp fa-solid fa-sort-down"></i>
              </p>
              <div className="borderActiv"></div>
            </div>
            <div id="bbb" className="bbb">
              <HashLink smooth to="/pays#terreWurt">
                Une terre Wurtembergeoise
              </HashLink>
              <HashLink smooth to="/pays#terreMarq">
                Une terre marquée ...
              </HashLink>
              <HashLink smooth to="/pays#terreProf">
                Une terre profondément marquée
              </HashLink>
              <HashLink smooth to="/pays#terreAud">
                Une terre d'audace et d'innovation
              </HashLink>
            </div>
          </span>
          <img src="./images/logo.png" alt="logoSEM" />
        </div>
      )}
    </>
  );
};

export default NavPays;
