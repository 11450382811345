import React from "react";

const Commande = () => {
  return (
    <div>
      <h1>Page commande</h1>
    </div>
  );
};

export default Commande;
