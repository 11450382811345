import axios from "axios";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import Url from "./Url";

const Card = (props) => {
  const url = <Url />;
  let date = new Date();
  const [isAdmin, setIsAdmin] = useState(false);
  const [cookies, setCookies] = useCookies(["token", "isLog", "id"]);
  const [name, setName] = useState();

  useEffect(() => {
    axios.get(url.type + `auth/${cookies.id}`).then((res) => {
      setName(res.data.username);
      setIsAdmin(res.data.isAdmin);
    });
  }, [cookies.id]);

  const deleteCard = (id) => {
    axios.delete(url.type + `envoi/${id}`).then(() => window.location.reload());
  };
  const navigate = useNavigate();
  const modifyCard = (id) => {
    navigate("/modify", { state: id });
  };

  return (
    <li key={props.index}>
      <div className="actionActivity">
        {isAdmin && (
          <input
            type="submit"
            className="deletePost"
            value="Supprimer"
            onClick={() => deleteCard(props.show.id)}
          />
        )}
        {isAdmin && (
          <input
            type="submit"
            className="modifyPost"
            value="Modifier"
            onClick={() => modifyCard(props.show.id)}
          />
        )}
      </div>

      <div
        dangerouslySetInnerHTML={{ __html: props.show.contenu }}
        className="dataPost"
      ></div>
      <div className="imgActivity">
        <img src={props.show.imgUrl} alt="" />
      </div>
      {/* <div className="info">
        <p>
          Posté le :{date.getDate(Date.parse(props.show.createdAt)) + "/"}
          {date.getMonth(Date.parse(props.show.createdAt)) + 1 + "/"}
          {date.getFullYear(Date.parse(props.show.createdAt))}
        </p>
        <p>par : {props.show.username}</p>
      </div> */}
    </li>
  );
};

export default Card;
