import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Url from "./Url";

const SignUp = (stateSign) => {
  const url = <Url />;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [infosForm, setInfosForm] = useState("");
  const onSubmit = (donnees) => {
    if (donnees.firstPassword === donnees.password) {
      axios
        .post(url.type + "auth/signup", donnees)
        .then(() => {
          setInfosForm(<div>Félicitation vous avez créé votre profil</div>);
          function x() {
            setInfosForm("");
            window.location.reload();
          }
          setTimeout(x, 3000);
        })
        .catch((err) => {
          if (err) {
            setInfosForm(err.response.data.message);
            function x() {
              setInfosForm("");
            }
            setTimeout(x, 3000);
          }
        });
    } else {
      setInfosForm(<div>Veuillez entrer 2 passwords identiques</div>);
      function x() {
        setInfosForm("");
        // window.location.reload();
      }
      setTimeout(x, 3000);
    }
  };

  const y = "formLoginShow";
  const [z, setZ] = useState("formLoginStart");

  useEffect(() => {
    if (stateSign.stateSign) setZ("formLoginHide");
  }, [stateSign.stateSign]);

  return (
    <div>
      <form
        id="formSignup"
        className={stateSign.stateSign ? y : z}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="id-password">
          <label htmlFor="username"></label>
          <input
            id="username"
            name="username"
            type="text"
            placeholder="Identifiant"
            {...register("username", {
              required: true,
              min: 3,
              maxLength: 100,
            })}
          />

          <label htmlFor="firstPassword"></label>
          <input
            id="firstPassword"
            name="firstPassword"
            placeholder="Mot de passe"
            type="password"
            {...register("firstPassword", { required: true, min: 5 })}
          />

          <label htmlFor="password"></label>
          <input
            id="password"
            name="password"
            placeholder="Confirmer mdp"
            type="password"
            {...register("password", { required: true, min: 5 })}
          />
        </div>
        <div className="name-firstname">
          <label> </label>
          <input
            {...register("name")}
            type="text"
            name="name"
            placeholder="Nom"
            autoComplete="off"
          />

          <label></label>
          <input
            {...register("firstname")}
            type="text"
            name="firstname"
            placeholder="Prénom"
            autoComplete="off"
          />
          <label> </label>
          <input
            {...register("profession")}
            type="text"
            name="profession"
            placeholder="Profession"
            autoComplete="off"
          />
        </div>
        <div className="email-tel">
          <label> </label>
          <input
            {...register("email")}
            type="text"
            name="email"
            placeholder="Email : xxx@gmail.com"
            autoComplete="off"
          />
          <label> </label>
          <input
            {...register("tel")}
            type="text"
            name="tel"
            placeholder="Tél : 00 00 00 00 00"
            autoComplete="off"
          />
          <div className="adresse">
            <label> </label>
            <input
              {...register("adresse")}
              type="text"
              name="adresse"
              placeholder="adresse : x rue ...."
              autoComplete="off"
            />

            <label> </label>
            <input
              {...register("codepostal")}
              type="number"
              name="codepostal"
              placeholder="code postal : xxxxx"
              autoComplete="off"
            />

            <label> </label>
            <input
              {...register("ville")}
              type="text"
              name="ville"
              placeholder="Ville"
              autoComplete="off"
            />
          </div>
        </div>

        <button type="submit" id="signup-btn">
          Ajouter
        </button>

        {infosForm}
      </form>
    </div>
  );
};

export default SignUp;
