import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import HomeContent from "../components/HomeContent";
import SignUp from "../components/SignUp";
import Up from "../components/Up";

const Home = () => {
  return (
    <div>
      <Up />
      <Header />

      <HomeContent />
      <Footer />
    </div>
  );
};

export default Home;
