import { useForm } from "react-hook-form";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import Url from "./Url";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ChangeMdp = (stateChange) => {
  const url = <Url />;
  const [cookies, setCookie] = useCookies(["token", "isLog", "id"]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = (donnees) => {
    axios

      // .post("http://localhost:3000/api/auth/login", donnees)
      .put(url.type + `auth/changemdp/${cookies.id}`, donnees)
      .then(console.log(donnees))
      .catch((err) => {});
  };

  const y = "formLoginShow";
  const [z, setZ] = useState("formLoginStart");

  useEffect(() => {
    if (stateChange.stateChange) setZ("formLoginHide");
  }, [stateChange.stateChange]);

  return (
    <div className="formS">
      <>
        <form
          id="formChange"
          className={stateChange.stateChange ? y : z}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <label htmlFor="newP">
              NOUVEAU MOT DE PASSE :{" "}
              <input
                autoCapitalize="none"
                name="newP"
                type="password"
                placeholder="Password"
                {...register("newP", { required: true, min: 5 })}
              />
            </label>
          </div>

          <button type="submit" id="loginBtn">
            Connexion
          </button>
        </form>
      </>
    </div>
  );
};

export default ChangeMdp;
