import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Up from "../components/Up";
import { FormContact } from "../components/FormContact";
import FormAdhesion from "../components/FormAdhesion";
const Adhesion = () => {
  return (
    <div>
      <Up />
      <Header />

      <div className="adhesion">
        <h2>Adhésion</h2>
        <h3>
          L'adhésion est de 31 euros pour l'année 2024.
          <br />
          Elle comprend le bulletin mémoires, et deux lettres semestrielles.
          <br /> L'accès aux conférences est libre.
        </h3>
        <div className="form-adhesion">
          <FormAdhesion />
        </div>

        <h4>
          Société d'Émulation de Montbéliard <br />
          BP251
          <br />F - 25204 MONTBELIARD CEDEX
        </h4>
        <div className="bon">
          <a href="./images/adhesion.pdf">
            Télécharger ou imprimer
            <br /> votre bon d'inscription
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Adhesion;
