import React, { useEffect, useState } from "react";

const Background = (theme) => {
  const currentTheme = theme.theme;

  const original = <img src="./images/chateau.jpg" alt="bg" />;

  const [fondImage, setFondImage] = useState(original);
  const [fondColor, setFondColor] = useState("fond");

  useEffect(() => {
    if (currentTheme === "noel") {
      setFondImage(<img src="./images/chateauneige.png" alt="bg" />);
    }
    if (currentTheme === "dark") {
      setFondColor("darkFond");
      setFondImage("");
    }
  }, [currentTheme]);

  return (
    <>
      <div className={"fond"}>{fondImage}</div>
    </>
  );
};

export default Background;
