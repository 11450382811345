import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import NavPays from "../components/NavPays";
import Up from "../components/Up";

const Pays = () => {
  return (
    <div id="terreWurt">
      <NavPays />
      <Up />
      <Header />
      <div id="terre">
        <h2>Une terre wurtembergeoise tardivement rattachée à la France</h2>
        <div className="terreWurt">
          <h3>De fameuses fiançailles</h3>
          <p>
            Jusqu’au néolithique, le Pays, terre de passage entre Jura et
            Vosges, connaît une occupation humaine intermittente qui se densifie
            ensuite, en particulier dès le VIIIè s. avant J.-C. Les périodes
            gauloise et gallo-romaine sont prospères, illustrées par l’essor de
            l’agglomération d’Epomanduodurum (Mandeure), la seconde de Séquanie
            derrière Vesontio (Besançon). Après le choc des invasions
            germaniques, le Xè s. voit l’émergence de Montbéliard, pôle
            principal de la région qui devient un comté, faisant des comtes de
            Montbéliard des seigneurs à la fois alsaciens, germaniques, comtois
            et bourguignons, illustrant bien le fait que cette terre est une
            terre de contacts entre Rhin et Rhône, entre Jura et Vosges. La
            ville se développe en même temps que s’affirme l’autorité des
            comtes, vassaux de l’empereur et du comte de Bourgogne, disposant de
            solides relations et de pouvoirs étendus.
            <br />
            <br /> L’histoire du Pays va connaître un tournant décisif avec
            l’union de la comtesse de Montbéliard et du prince de Wurtemberg. Le
            comté étant transmissible par les femmes, particularité du droit
            montbéliardais, Henriette de Montbéliard (1383-1444) en hérite en
            1397 et, le 13 novembre de la même année, est promise au comte de
            Wurtemberg Eberhard IV : avec cette union, c’est la naissance d’une
            dynastie qui règnera durant quatre siècles, rapprochant le Pays de
            Montbéliard de l’Empire germanique et l’écartant de la Bourgogne et
            de la France, avec des conséquences décisives. Désormais, et
            jusqu’en 1793, le petit comté fut la possession d’un souverain
            wurtembergeois, avec une dynastie plus locale du XVIè au XVIIè s. et
            un retour aux souverains de Stuttgart au XVIIIe.
          </p>
          <h3>Un petit Etat souverain</h3>
          <p>
            Résident au château de Montbéliard, le prince, souverain absolu,
            était assisté depuis le XVIè s. d’un Conseil de Régence, véritable
            gouvernement, dont il choisissait les conseillers. Conseil d’Etat,
            chambre des finances, tribunal, conseil ecclésiastique,
            administration centrale, il avait un rôle considérable dans tous les
            domaines, particulièrement au XVIIIè s., lorsque les souverains ne
            résidèrent plus à Montbéliard, représentés par un gouverneur, puis,
            dès 1786, par un « stathouder ». Ce petit Etat était donc une terre
            wurtembergeoise, régie par le droit public d’Allemagne, et les lois
            et usages du comté de Bourgogne y furent beaucoup transformés,
            créant un réel particularisme montbéliardais. Cependant la
            population resta francophone, l’allemand n’étant utilisé que par
            l’administration centrale, alors que les élites étaient le plus
            souvent bilingues.
            <br />
            <br /> Au cœur de cet Etat, la ville de Montbéliard est un cas
            singulier. Bénéficiant depuis 1283 d’une charte de franchise
            octroyée par le comte Renaud de Bourgogne, la cité est une petite
            république urbaine dirigée par les bourgeois, avec ses droits, ses
            pouvoirs, ses privilèges confirmés par chaque souverain lors de son
            avènement. Le gouvernement municipal, ou Magistrat, était assuré par
            trois corps, émanation des familles bourgeoises inscrites dans le «
            livre rouge » : les Neuf maîtres bourgeois, les XVIII et les
            Notables qui avaient voix délibérative pour les décisions à prendre.
            Le Magistrat gérait la ville mais le comte avait un droit de regard
            sur les finances et des possibilités d’intervention dans le domaine
            économique ; il disposait aussi d’un représentant dans l’assemblée
            municipale qui l’informait des décisions prises et transmettait ses
            avis. Tout au long de l’Ancien Régime, surtout au XVIè s., les
            rapports entre les deux pouvoirs furent souvent tendus, mais sans
            dérive violente, et débouchant toujours sur des compromis
            acceptables.
            <br />
            <br /> La domination wurtembergeoise eut pour le Pays des
            conséquences importantes. D’abord, il se trouva dès la seconde
            moitié du XVIIè s. en situation d’enclavement entre deux provinces
            françaises, l’Alsace et la Franche-Comté, proie convoitée et en
            apparence facile pour les souverains bourbons. Il se trouva aussi
            isolé sur le plan économique privé d’une ouverture sur un grand
            marché. En revanche, sur le plan intellectuel, ses élites furent
            formées à Stuttgart, Tübingen ou Bâle. Le passé germanique du Pays
            est perceptible aujourd’hui dans l’architecture où les traces de
            l’œuvre de l’architecte wurtembergeois Heinrich Schickhardt sont
            bien visibles.
          </p>
          <h3>Un tardif rattachement à la France</h3>
          <p>
            Dès 1678, date de l’annexion de la Franche-Comté, les Français
            multiplient les pressions sur le petit territoire, occupé
            militairement jusqu’en 1698. Les années qui suivent sont marquées
            par l’annexion des seigneuries de Blamont, Clémont, Héricourt et
            Châtelot : le grignotage commence sans que le Wurtemberg puisse
            réagir, sinon diplomatiquement, ce qui freina cependant les appétits
            français. Manquant de moyens financiers et ne pouvant plus envisager
            un affrontement brutal, la France reste à l’affût, prête à saisir la
            moindre occasion, en particulier sur le plan économique. Pour
            étouffer le Pays, elle l’enserre d’un solide corset douanier et fait
            en sorte que la grande route royale de Besançon à Strasbourg évite
            soigneusement Montbéliard. Cette guerre économique qui touchait de
            plein fouet l’activité locale préparait de façon sournoise mais
            inéluctable l’annexion. Dans ces conditions, la bourgeoisie
            marchande et les entrepreneurs, souhaitant briser le corset douanier
            pour élargir leurs perspectives, envisageaient de plus en plus
            positivement une annexion française, alors que le Magistrat était
            plutôt favorable au statu quo et que le peuple, sévèrement touché
            par la crise de subsistance de 1789-1790, souhaitait voir son sort
            s’améliorer.
            <br />
            <br /> La dégradation du climat économique et les tensions sociales
            croissantes conduisent le stathouder Frédéric Eugène à quitter le
            Pays le 27 avril 1792 pour retourner en Wurtemberg. Le pouvoir est
            de fait vacant, avec un Conseil de Régence impuissant et un
            Magistrat paralysé et c’est à l’instigation d’un groupe de marchands
            que le conventionnel Bernard de Saintes vient, au nom de la France,
            prendre possession de Montbéliard et de la Principauté. Il arrive le
            10 octobre 1793 de Belfort avec un escadron de cavalerie, un
            bataillon d’infanterie et un canon.
            <br />
            <br /> C’est la fin, sans violence, d’une indépendance séculaire :
            le Pays est devenu français. Passées les hésitations de la
            Convention et la mascarade du culte local de l’Etre suprême, les
            habitants, sans avoir manifesté la moindre hostilité à leurs
            nouveaux maîtres, deviennent de bons Français et de bons
            républicains. Mais dans l’immédiat le rattachement à la France
            n’avait pas été très favorable au Pays : ainsi Montbéliard,
            ex-capitale d’un petit Etat, se trouva d’abord reléguée au rang de
            modeste chef-lieu de Haute-Saône. En 1797 le Pays fut rattaché au
            département du Mont-Terrible, supprimé en 1800, puis au Haut-Rhin,
            avant d’être définitivement rattaché au Doubs en 1816, Montbéliard
            devenant sous-préfecture. Dès lors, le Pays vit au rythme de la
            France, subissant courageusement sur son sol les effets des conflits
            franco-allemands.
            <br />
            <br /> Durant la guerre de 1870, il supporte une rude occupation
            prussienne et la retraite du général Bourbaki (janvier 1871). Il
            accueille des Alsaciens et Lorrains quittant leur province annexée
            pour ne pas prendre la nationalité allemande. Il devient aussi, avec
            le tracé de la nouvelle frontière, une zone essentielle, hâtivement
            fortifiée, pour la protection du territoire national. Durant la
            première guerre mondiale il est une base arrière proche du front où
            tout le puissant potentiel industriel est mis au service de la
            défense. Après l’invasion de juin 1940, le Pays est soumis à une
            très brutale occupation allemande qui multiplie les exactions,
            surtout à partir de 1942. Une résistance active et multiforme
            s’organise : filières d’exfiltration vers la Suisse, sabotages,
            renseignement, maquis d’Ecot et du Lomont. La répression nazie est
            terrible, dans la ville comme dans les villages alentour. La
            libération par la Première armée française arrive le 17 novembre
            1944 après de rudes combats.
          </p>
        </div>

        <div id="terreMarq"></div>
        <h2>Une terre marquée par le luthéranisme</h2>
        <div className="terreMarq">
          <h3>L’installation précoce de la Réforme</h3>
          <p>
            La marque du luthéranisme constitue l’autre originalité historique
            du Pays de Montbéliard. Dans un contexte de crise spirituelle commun
            à toute l’Europe occidentale, le prince Ulrich Ier acquis aux idées
            luthériennes, fit appel à Guillaume Farel, dauphinois alors exilé à
            Bâle, pour qu’il vienne prêcher la Réforme à Montbéliard ; or
            celui-ci s’y emploie avec une telle véhémence que l’on doit
            l’expulser en 1525. Son passage fut bref, mais il fut le premier à
            introduire dans la ville les idées de Luther et y laissa des livres
            pieux. Dix ans plus tard, Ulrich demanda au prédicateur Pierre
            Toussain, venu de Metz, de poursuivre la tâche. Proche de Luther et
            des théologiens wurtembergeois, il parvint, au prix d’une activité
            inlassable à enraciner les idées réformées dans la ville : le culte
            catholique y est aboli en 1538 et la Réforme gagne les campagnes du
            comté au cours des dix années suivantes. Les catholiques sont de
            moins en moins nombreux, alors qu’arrivent de Suisse et de France
            des prédicateurs calvinistes, créant de vives tensions avec les
            luthériens, sans que jamais cela ne dégénère en violences physiques
            : il n’y eut pas de guerres de religion dans le Pays. Sous le règne
            du comte Georges (1553-1558), la Réforme luthérienne est
            définitivement installée : le prince, conforté dans son pouvoir
            religieux par la paix d’Augsbourg (1555), selon laquelle les sujets
            devaient suivre la religion de leur souverain, contraint les
            habitants du Pays de Montbéliard à adopter sa conception de la
            Réforme : un luthéranisme strict et wurtembergeois.
            <br />
            <br /> Cela n’empêche pas les difficultés religieuses : manque de
            livres sacrés, organisation précaire, liturgie peu rigoureuse,
            tensions croissantes entre luthériens et calvinistes renforcés par
            l’arrivée de nombreux huguenots. Le luthéranisme voulu par le
            pouvoir irrite donc une partie de la population qui souhaite une
            plus grande liberté religieuse. Face à cette situation, le prince
            Frédéric (1558-1608), soucieux de son autorité et féru de théologie,
            impose fermement un luthéranisme officiel, après avoir essayé, en
            vain, de trouver un compromis. Le 26 décembre 1586, afin de couper
            court aux troubles et aux tensions, il fait publier la « confession
            de Montbéliard » que doivent accepter tous les habitants : les
            récalcitrants devaient se soumettre ou partir. La religion du prince
            est reconnue comme religion d’Etat, associant de façon très étroite
            les domaines civil et religieux, avec Montbéliard comme centre d’un
            petit diocèse luthérien et siège de son « évêque suprême », le
            prince. Fort de cette unité retrouvée, Frédéric acheva l’œuvre
            d’organisation religieuse commencée par ses prédécesseurs :
            installation des pasteurs, assistance, nouvelles paroisses, nouveaux
            temples, mise en place de visites ecclésiastiques. Achevé en 1604,
            le temple Saint-Martin, cadeau de Frédéric à ses sujets et réalisé
            par son architecte Heinrich Schickhardt, consacrait le triomphe du
            luthéranisme et devenait le symbole éclatant de l’autorité
            religieuse du prince en même temps que l’illustration d’une
            Renaissance montbéliardaise. Le Pays était devenu le seul territoire
            luthérien d’expression française, ce qui n’était pas sa moindre
            originalité et entraîna pour lui de lourdes conséquences.
          </p>
          <h3>Un impact considérable</h3>
          <p>
            Le passage à la Réforme imposa une marque profonde. Il fit naître
            une éthique collective faite de culte du travail, d’austérité et de
            rigueur, de refus du paraître et de discrétion conséquences du
            contrôle social imposé par les pasteurs dans les paroisses. Le
            luthéranisme, religion du Livre, qui imposait à tout fidèle un accès
            direct aux Ecritures, fut à l’origine d’un effort d’alphabétisation
            précoce : le Pays se couvrit d’écoles, directement liées à
            l’enseignement religieux et placées sous l’autorité des pasteurs ; à
            la veille du rattachement à la France, il y avait une école par
            paroisse, accueillant garçons et filles et le taux d’alphabétisation
            était déjà très élevé, donnant au Pays une avance réelle par rapport
            à ses voisins. A Montbéliard, un gymnase (ou lycée) permettait aux
            fils de l’élite de faire leurs humanités, les uns, futurs pasteurs,
            rejoignant ensuite l’université de Tübingen, les autres l’Académie
            caroline de Stuttgart, dont le plus illustre étudiant fut Georges
            Cuvier.
            <br />
            <br /> Le passage du Pays au protestantisme eut sous Louis XIV des
            conséquences dramatiques. Depuis le rattachement de l’Alsace (1648)
            et la conquête de la Franche-Comté (1674), le roi catholique ne
            pouvait tolérer aux frontières de son royaume une enclave
            protestante. Le 5 novembre 1676, les Français investissent
            Montbéliard et y pénètrent quatre jours plus tard. Impuissant, le
            prince Georges II quitte la ville et se réfugie à Bâle. L’occupation
            française va durer plus de vingt ans, marquée par des outrages
            répétés et une vigoureuse tentative de « recatholicisation » :
            processions provocatrices, persécution des pasteurs, démolition de
            Saint-Martin évitée de justesse, installation d’un curé et
            rétablissement d’un culte catholique dans la ville et dans les
            seigneuries de Blamont, Clémont, Héricourt et Châtelot. Le traité de
            Ryswick oblige Louis XIV à évacuer le comté mais il impose le
            maintien d’un curé à Montbéliard avec un culte catholique suivi par
            de rares fidèles.
          </p>
          <h3>Une terre d’accueil et d’oecuménisme</h3>
          <p>
            Terre protestante, le Pays fut très tôt une terre de refuge. D’abord
            pour les huguenots français fuyant les guerres de religion ou les
            persécutions de Louis XIV : c’est d’ailleurs à leur intention que
            fut construit le temple Saint-Georges en 1674 dans le quartier de la
            Neuve Ville. Au début du XVIIè siècle, le prince Léopold-Eberhard
            fit venir des fermiers anabaptistes alsaciens afin de développer et
            moderniser l’agriculture et l’élevage. Ils firent souche et
            constituèrent une communauté spirituelle dynamique en marge du
            luthéranisme officiel. Avec le rattachement à la France et le
            triomphe de la liberté religieuse, le Pays devint dès la fin du XIXè
            siècle une terre de foisonnement spirituel dans la mouvance
            protestante : en marge d’un luthéranisme toujours majoritaire se
            développaient des communautés évangéliques, baptistes,
            anabaptistes-mennonites, darbystes, sans oublier l’Armée du Salut
            qui connut un remarquable essor.
            <br />
            <br /> Parallèlement, le catholicisme, marginal au début du XIXè s.,
            se développa en même temps que l’industrie. Pour faire fonctionner
            ateliers et usines, le patronat protestant fit venir de la
            main-d’œuvre de l’extérieur : du Doubs, de la Haute-Saône et du Jura
            catholiques, et plus tard d’Italie, de Pologne ou du Portugal. Dès
            1e milieu du XIXè s., les catholiques étaient majoritaires dans le
            Pays, ce qu’illustre bien l’arrogante construction de l’église
            Saint-Maimboeuf de Montbéliard. En 1914, même si subsistaient
            certaine méfiances, les catholiques étaient parfaitement intégrés
            dans la ville , mais sans en détenir les pouvoirs. Au début du XIXè
            s. existait une petite communauté israélite qui fut revigorée avec
            les réfugiés d’Alsace-Lorraine après la guerre de 1870 ; elle était
            parfaitement intégrée et fut presque entièrement décimée par la
            répression nazie.
            <br />
            <br /> Le Pays fut enfin une terre de naissance du mouvement
            oecuménique. Le poids du passé aidant, catholiques et protestants se
            comprenaient assez mal ou s’ignoraient. La seconde guerre mondiale
            permit la naissance de nouvelles solidarités. Le combat commun dans
            la résistance conduisit, dès la fin des années 1950, aux premières
            réflexions sur l’œcuménisme avec l’abbé Flory, archiprêtre de
            Montbéliard, le pasteur Marchand de Belfort et le pasteur Chargeraud
            de Montbéliard. Dans ce domaine, le Pays eut aussi un rôle pionnier
            et d’entraînement.
          </p>
        </div>
        <div id="terreProf"></div>
        <h2>Une terre profondément marquée par l’industrie</h2>
        <div className="terreProf">
          <p>
            Le développement précoce d’un puissant pôle industriel reste
            aujourd’hui la caractéristique majeure du Pays. Les origines de cet
            essor sont anciennes. Entre la fin du XVIé s. et le début du XVIIè
            se développent à Chagey et à Audincourt des fourneaux et des forges
            qui sont propriété du prince. L’activité textile n’est pas en reste
            avec les tisserands de Montbéliard et Héricourt qui fabriquent les
            traditionnels tissus de chanvre , la fameuse « verquelure », puis
            dès la fin du XVIIIè s. les premières indiennes.
            <br />
            <br /> Les origines de cette première poussée industrielle sont
            multiples : présence de nombreux cours d’eau fournissant
            l’indispensable énergie hydraulique, couvert forestier très dense
            apportant le charbon de bois, gisements métallifères nombreux et
            faciles à exploiter, culte du travail et de l’effort lié à l’éthique
            protestante, élites bien formées sensibles aux sciences et aux
            techniques, nécessité de trouver dans un territoire aux sols
            médiocres des activités complémentaires.
            <br />
            <br /> Le rattachement à la France, qui ouvrait aux industries
            locales un immense marché, permit aux entrepreneurs locaux de donner
            toute la mesure de leurs ambitions, relayées ensuite par les
            encouragements du Consulat et de l’Empire. Il n’est pas indifférent
            de noter que les deux entreprises les plus dynamiques du secteur
            naissent à cette époque, Japy en 1794 et Peugeot en 1810. La
            dynamique était lancée ; elle ne s’arrêterait plus.
          </p>
          <h3>La construction d’un bastion industriel</h3>
          <p>
            Emergente au début du XIXè siècle, l’industrie s’impose par la suite
            comme dominante, favorisée par le contexte général et l’amélioration
            des moyens de communications : désenclavement routier, canal du
            Rhône au Rhin (1825-1834), arrivée du chemin de fer (1858).
            Désormais le marché s’étendait à la France et à l ‘Europe.
            L’entreprise Japy, bâtie sur l’activité horlogère, diversifie sa
            gamme avec la quincaillerie, la lustrerie, les machines à écrire,
            incarnant en 1914 le dynamisme manufacturier du Pays. En 1930, faute
            d’une gestion adaptée, confrontée à la crise et à la concurrence
            internationale, la société est contrainte à de rudes
            restructurations. Le dernier site de production fermera ses portes
            en 1980.
            <br />
            <br /> De son côté, Peugeot illustre aussi une très belle réussite,
            davantage ancrée dans la longue durée. En 1851, la société Peugeot
            frères, fondée par Jules et Emile Peugeot fabrique dans ses usines
            de Valentigney, Pont-de-Roide et Mandeure des produits
            métallurgiques variés. Entre 1882 et 1886, avec Armand, fils
            d’Emile, la société se lance dans la production de cycles à
            Beaulieu. C’est aussi Armand qui fait le pari audacieux mais
            visionnaire de l’automobile, créant en 1896 Société des automobiles
            Peugeot dont l’usine principale se trouve alors à Audincourt. En
            1910 naît la Société des automobiles et cycles Peugeot. En 1912
            était inaugurée l’usine de Sochaux, devenue rapidement le principal
            site de l’entreprise. En 1926, les Peugeot emploient 40% de la
            main-d’œuvre industrielle locale. Deux ans plus tard commence la
            production en série. En moins d’un siècle, une très grande
            entreprise, expression de son terroir, était née.
            <br />
            <br /> Outre Japy et Peugeot, jusqu’à la seconde guerre mondiale les
            activités industrielles restèrent relativement diversifiées :
            textile à Montbéliard et Audincourt, horlogerie longtemps très
            puissante (46% de l’emploi industriel en 1879), travail du bois,
            grosse métallurgie à Audincourt. Mais après la seconde guerre
            mondiale l’essor spectaculaire de Peugeot conduisit peu à peu à une
            mono-industrie automobile qui fit peu à peu disparaître les autres
            industries, confrontées par ailleurs à une terrible concurrence
            étrangère. La demande est frénétique et Peugeot se lance à l’assaut
            des marchés extérieurs (en 1959, 40% des 200000 véhicules produits
            sont exportés). Les usines emploient plus de 40000 salariés en 1973.
            Dès 1979, avec les chocs pétroliers, l’essoufflement du marché, les
            effets du rachat de deux entreprises en difficultés, Citroën et
            Chrysler-France, Peugeot connaît de graves problèmes, mais réussit,
            au prix de mesures drastiques à rétablir la situation et à retrouver
            un nouvel élan. Aujourd’hui l’usine de Sochaux emploie moins de
            12000 salariés, dans un contexte économique difficile.{" "}
          </p>
          <h3>Une marque profonde</h3>
          <p>
            Cet essor industriel précoce imposa au Pays sa marque profonde. La
            population passa de 50600 habitants en 1851, à 86800 en 1946 et à
            139000 en 1975. Cette croissance toucha en priorité les bourgs
            devenus cités industrielles et fut surtout le résultat de
            l’immigration d’une main-d’œuvre paysanne locale et étrangère de
            plus en plus nombreuse jusqu’au début des années 1980 (20% de la
            main-d’œuvre de Peugeot en 1975), venue d’Europe centrale et
            méditerranéenne, du Maghreb, des Balkans et de Turquie.
            <br />
            <br /> L’afflux massif de population conduisit à une urbanisation
            accélérée. Les besoins de logement amènent le patronat à favoriser
            l’habitat ouvrier dans le cadre d’un paternalisme bien compris et
            efficace. Après 1945, l’extraordinaire essor industriel entraine de
            profondes mutations dans le paysage. Entre 1955 et 1978, de grands
            ensembles sortent partout de terre pour loger d’urgence une
            population en pleine expansion : 16000 logements sont construits sur
            le territoire. Parallèlement, se multiplient les quartiers
            pavillonnaires intéressant d’abord les cadres, puis très vite les
            ouvriers. Le paysage, qui était resté longtemps marqué par
            l’activité agricole, devient urbain, illustrant parfaitement les
            mutations de la France contemporaine. Dans un contexte économique
            difficile, le Pays garde des capacités d’adaptation et de réaction
            considérables ; il est capable d’imagination et d’innovation, comme
            il l’a souvent montré dans le passé.
          </p>
        </div>
        <div id="terreAud"></div>
        <h2>Une terre d’audace et d’innovations</h2>
        <div className="terreAud">
          <h3>Scientifiques, ingénieurs et inventeurs</h3>
          <p>
            Depuis fort longtemps , le Pays de Montbéliard fut le berceau de
            nombreux scientifiques ou inventeurs. Il faut sans doute voir là les
            effets d’une alphabétisation précoce et massive, d’un intérêt ancien
            pour les sciences et les techniques, au contact d’une culture
            germanique plus ouverte à la créativité et à l’innovation . Dès le
            XVIè siècle, Jean Bauhin (1541-1612) fut un médecin et botaniste
            renommé dont l’ « Historia plantarum » eut un écho européen. Plus
            tard, Georges Cuvier (1769-1832) fut le créateur de l’anatomie
            comparée et de la biostratigraphie ; dans son sillage émergea toute
            une lignée de naturalistes montbéliardais qui eurent pour certains
            un rayonnement national. Inventeur particulièrement fécond, Etienne
            Oehmichen (1884-1955) fut, entre autres choses, l’un des inventeurs
            de l’hélicoptère. Adolphe Kégresse (1879-1943) fut l’inventeur de la
            chenille souple pour véhicules automobiles. Plus près de nous, René
            Thom (1923-2002), médaille Fields de mathématiques en 1958, fut le
            fondateur de la théorie des catastrophes, alors que Pierre Marti
            (1891-1938) avait mis au point une technique révolutionnaire de
            sondage acoustique qui eut des applications décisives dans la
            reconnaissance des fonds marins et la détection des submersibles.
            Cette liste non exhaustive montre la vitalité et le génie inventif
            de ce petit pays.
            <br />
            <br /> C’est aussi là que naquit Lucien Quélet (1832-1899),
            mycologue renommé et fondateur de la Société mycologique de France,
            donnant naissance à toute une lignée de mycologues locaux dont
            l’audience dépassa très largement les limites du territoire. Issus
            de la communauté anabaptiste, dont les compétences en matière
            d’élevage étaient reconnues depuis longtemps, les fermiers Graber et
            Lugbul, à force de croisements empiriques, furent à l’origine de la
            race montbéliarde dont la première mention du nom apparut en 1872
            avant sa reconnaissance officielle en 1889. Le rayonnement mondial
            de cette vache laitière particulièrement rustique et productive est
            aujourd’hui une évidence. Dans un domaine différent, on peut
            rattacher à ces « inventions locales » la naissance du premier club
            de football professionnel à l’initiative de l’entre prise Peugeot en
            1928, le Football club de Sochaux.
          </p>
          <h3>Le choix pionnier de l’intercommunalité</h3>
          <p>
            Après la seconde guerre mondiale, le Pays est confronté à une
            croissance démographique frénétique qui génère un effort de
            construction sans précédent. Montbéliard et toutes les communes
            péri-urbaines sont concernées avec la menace récurrente d’un
            développement anarchique et la nécessité de faire face, dans des
            délais très rapides, à de gros problèmes d’équipement communal en
            termes de voirie, d’approvisionnement et de traitement des eaux, de
            circulation. Cette nécessité de coordination et de structuration,
            ajoutée aux incitations étatiques, conduisit en juillet 1959 des
            élus visionnaires à la création du District urbain du Pays de
            Montbéliard. C’était le second de France derrière celui de
            Montargis, mais de loin le plus important et le plus ambitieux.
            Devenue communauté d’agglomération depuis 1999, sous le sigle PMA
            (pour Pays de Montbéliard Agglomération), cette structure
            communautaire reste la plus importante de France par son budget et
            la population concernée.
            <br />
            <br /> Elle regroupe aujourd’hui 29 communes et plus de 110000
            habitants. Elle a permis depuis ses origines la réalisation de
            grands équipements structurants : assainissement, service des eaux,
            transports urbains, traitement des déchets, lutte contre les
            inondations, protection de l’environnement, création de zones
            industrielles, équipement scolaire secondaire, création d’un pôle
            universitaire. Elle concentre aujourd’hui ses efforts sur l’action
            économique et vient de se donner une compétence culturelle.
            S’efforçant de diversifier le tissu économique et de soutenir
            l’activité, elle reste l’un des outils locaux majeurs de lutte
            contre la crise.
          </p>
          <h3>Le premier jumelage franco-allemand de France</h3>
          <p>
            La ville de Montbéliard fut au cœur de la première tentative de
            rapprochement franco-allemand après la seconde guerre mondiale. Le
            contexte était propice avec la naissance en 1948 de l’institut
            franco-allemand et le 9 mai 1950 le discours de Robert Schumann en
            faveur de l’amitié européenne et de la réconciliation
            franco-allemande. Mais il restait beaucoup de réticences à surmonter
            parmi les populations. A Montbéliard, Lucien Tharradin, maire d’une
            ville qui avait subi une occupation allemande très dure, lui même
            résistant et déporté, a la hauteur de vue nécessaire pour surmonter
            les vielles rancoeurs. Le 31 mai 1950 il rencontre le bourgmestre de
            Ludwigsbourg Elmar Doch qui partage la même vision. Le processus est
            enclenché. Le choix de Ludwigsbourg n’est pas indifférent : la ville
            située près de Stuttgart est l’ancienne capitale des ducs de
            Wurtemberg ce qui montre quel fut le poids de l’histoire et des
            liens anciens entre les deux territoires.
            <br />
            <br /> Le 25 août 1950 le conseil municipal de Montbéliard s’engage
            en faveur d’une coopération étroite avec Ludwigsbourg. En septembre
            une délégation montbéliardaise dirigée par Lucien Tharradin se rend
            en Allemagne et le mois suivant une délégation ludwigsbourgeoise
            vient à Montbéliard. Les premiers contacts ont donc bien eu lieu en
            1950, date habituellement reconnue comme année de naissance du
            jumelage, alors que celui –ci n’est pas encore officiel. Le
            démarrage est timide, limité au début à des compétitions de
            football, mais le jumelage prend une nouvelle dimension en 1958 avec
            des visites annuelles et l’idée d’échanges scolaires. Le 6 mai 1962
            une cérémonie officielle entérine le jumelage quelques mois avant la
            fameuse adresse du général de Gaulle à la jeunesse allemande à
            Ludwigsbourg le 9 septembre 1962 ; c’est un véritable triomphe,
            rendant en quelque sorte un hommage implicite aux visionnaires des
            deux villes.
            <br />
            <br /> En 1975, à l’occasion du 25è anniversaire du jumelage, les
            deux cités sont récompensées par l’Association France-Allemagne ;
            puis de nouveau en 1990 par l’attribution du prix de Gaulle-Adenauer
            pour leur contribution au service de la coopération
            franco-allemande. Depuis l’origine, les échanges entre les deux
            villes se sont étendus et diversifiés : animations sportives et
            culturelles, échanges scolaires et culturels, rencontres
            professionnelles, visites mutuelles de délégations municipales,
            voyages ; depuis 2006, le jumelage a pris une autre dimension avec
            la mise en oeuvre d’un programme de coopération décentralisée avec
            le Burkina-Faso. On peut noter aussi que des contacts amicaux ont
            été noués avec la famille de Wurtemberg et que le duc Karl est
            régulièrement invité aux manifestations historiques et
            patrimoniales. Là encore, l’histoire peut être un facteur de
            compréhension et de rapprochement et non de division.
            <br />
            <br />
            <br />
            <br /> Le Pays de Montbéliard est une terre attachante, riche d’une
            histoire originale, mais aussi résolument tournée vers l’avenir et
            qui se bat pour affronter les défis du futur. C’est une terre de
            convictions et d’adaptation qui a toujours su trouver des solutions
            pour affronter les difficultés. Depuis la fin de la seconde guerre
            mondiale, le Pays est devenu un creuset où le vieux fond
            wurtembergeois et luthérien a été submergé, tout en conservant de
            puissantes singularités locales, alliance de tradition et de
            modernité, qui font sa richesse et sa force.
          </p>
          <p>
            <br />
            <br />
            François Vion-Delphin
            <br /> Président de la Société d'Emulation de Montbéliard
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Pays;
