import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import Url from "./Url";
import { HashLink } from "react-router-hash-link";
import Login from "./Login";
import SignUp from "./SignUp";
import ChangeMdp from "./ChangeMdp";
//import SignUp from "./components/SignUp";
const NavConnexion = () => {
  const url = <Url />;
  const [cookies, setCookie, removeCookie] = useCookies([
    "token",
    "isLog",
    "id",
  ]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [name, setName] = useState();
  const [avatar, setAvatar] = useState("./images/avatarneutre.png");
  console.log(avatar);
  axios.defaults.headers.common["Authorization"] = "Bearer " + cookies.token;

  useEffect(() => {
    axios.get(url.type + `auth/${cookies.id}`).then((res) => {
      if (res.data.avatar == null) {
        setAvatar("./images/avatarneutre.png");
      } else {
        setAvatar(res.data.avatar);
      }
      console.log(avatar);
      setName(res.data.username);
      setIsAdmin(res.data.isAdmin);
    });
  }, [cookies.id]);

  const handleRemove = () => {
    removeCookie("isLog", { path: "/" });
    removeCookie("token", { path: "/" });
    removeCookie("id", { path: "/" });
  };
  const [count, setCount] = useState(false);
  const handleShow = () => {
    setCount(!count);
  };
  const [stateLog, setStateLog] = useState(false);
  const [stateSign, setStateSign] = useState(false); /// Mettre a true pour créer ADMIN
  const [stateChange, setStateChange] = useState(false);

  const handleLogin = () => {
    setStateLog(!stateLog);
  };

  const handleSign = () => {
    setStateSign(!stateSign);
  };

  const handleChangemdp = () => {
    setStateChange(!stateChange);
  };

  return (
    <>
      <div className="navCo">
        {cookies.isLog && cookies.token ? (
          <div className="avatar">
            <div className="avatarArrow">
              <img src={avatar} alt="avatar" onClick={handleShow} />
              <i id="arrow" className="fa-sharp fa-solid fa-sort-down"></i>
            </div>

            {count && (
              <div id="bonjour">
                <p>Nom : {name}</p>
                <p>
                  {isAdmin ? (
                    <span> Administrateur</span>
                  ) : (
                    <span> Adhérent</span>
                  )}
                </p>
                <div className="traitNavCo"></div>
                {isAdmin && (
                  <>
                    <div className="actionAvatar">
                      <HashLink to="#top" id="navSignup" onClick={handleSign}>
                        {!stateSign ? (
                          <i
                            className="fa-solid fa-user-plus"
                            title="Ajouter un membre"
                          ></i>
                        ) : (
                          <i id="closeSign" className="fa-solid fa-xmark"></i>
                        )}
                      </HashLink>
                      <NavLink to="/admin" id="admin">
                        <i
                          class="fa-solid fa-user-tie"
                          title="Voir tous les membres"
                        ></i>
                      </NavLink>
                    </div>
                    <div className="traitNavCo"></div>
                  </>
                )}

                <div className="actionAvatar">
                  <HashLink to="#top" id="navSignup" onClick={handleChangemdp}>
                    {!stateChange ? (
                      <i
                        className="fa-solid fa-user-gear"
                        title="Modifier mes infos"
                      ></i>
                    ) : (
                      <i id="closeSign" className="fa-solid fa-xmark"></i>
                    )}
                  </HashLink>
                  <NavLink to="/parametres" id="parametre">
                    <i className="fa-solid fa-gear"></i>
                  </NavLink>
                  {!isAdmin && (
                    <NavLink to="/membre" id="member">
                      <i
                        className="fa-solid fa-user-astronaut"
                        title="Page membre"
                      ></i>
                    </NavLink>
                  )}
                </div>

                <div className="traitNavCo"></div>
                <NavLink
                  to="/"
                  id="deco"
                  // onMouseDown={handleInfos}
                  onClick={handleRemove}
                >
                  <i className="fa-solid fa-circle-arrow-left"></i>
                  Se déconnecter
                </NavLink>
              </div>
            )}
          </div>
        ) : (
          <HashLink
            to="#top"
            id="navLogin"
            className={(nav) => (nav.isActive ? "nav-active" : "")}
            onClick={handleLogin}
          >
            {stateLog ? <span>Annuler</span> : <span>Se connecter</span>}
            <div className="borderNav"></div>
          </HashLink>
        )}

        {/* <NavLink to="/signup" id="navSignup">
          <i class="fa-solid fa-user-plus"></i>
        </NavLink> */}

        {/*cookies.isLog && cookies.token && (
        <NavLink to="/pageperso">Page Perso</NavLink>
      )*/}
      </div>

      {/*stateSign ? <SignUp /> : ""*/}

      <SignUp stateSign={stateSign} />

      {/*stateLog ? <Login /> : ""*/}

      <Login stateLog={stateLog} />

      <ChangeMdp stateChange={stateChange} />
    </>
  );
};
export default NavConnexion;
