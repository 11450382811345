import Up from "../components/Up";
import { useEffect, useState } from "react";
import axios from "axios";
import Url from "../components/Url";
import { useForm } from "react-hook-form";

const Gestion = () => {
  const url = <Url />;
  const [reload, setReload] = useState(true);
  const [valueGestion, setValueGestion] = useState("BULLETINS PDF");
  const [typePdf, setTypePdf] = useState(true);
  const [statePdf, setStatePdf] = useState(true);
  const [stateDeletePdf, setStateDeletePdf] = useState(false);
  const [containPdf, setContainPdf] = useState([]);
  const [stateCP, setStateCP] = useState(true);
  const [stateDeleteCP, setStateDeleteCP] = useState(false);
  const [containCp, setContainCp] = useState([]);
  const [apercuCp, setApercuCp] = useState(false);
  const [imageCp, setImageCp] = useState();
  const [idApercuCp, setIdApercuCp] = useState("");

  useEffect(() => {
    axios.get(url.type + "pdf").then((res) => setContainPdf(res.data));
    axios.get(url.type + "cp").then((res) => setContainCp(res.data));
    axios.get(url.type + `cp/${idApercuCp}`).then((res) => "toto");
  }, [reload]);

  useEffect(() => {
    axios
      .get(url.type + `cp/${idApercuCp}`)
      .then((res) => setImageCp(res.data.cplien));
  }, [apercuCp]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (donnees) => {
    const formData = new FormData();
    formData.append("pdfname", donnees.pdfname);
    formData.append("image", donnees.pdflien[0]);
    formData.append("pdftype", typePdf);
    formData.append("pdfprix", donnees.pdfprix);
    formData.append("pdfyear", donnees.pdfyear);

    axios
      .post(url.type + "pdf", formData)
      .then((res) => {
        setReload(!reload);
      })
      .catch((err) => {
        console.log(err.response.data.error);
      });
  };

  const deletePdf = (id) => {
    axios.delete(url.type + `pdf/${id}`).then(() => window.location.reload());
  };

  const onSubmitCp = (donnees) => {
    const formData = new FormData();
    formData.append("cpname", donnees.cpname);
    formData.append("image", donnees.cplien[0]);
    formData.append("cpprix", donnees.cpprix);

    axios
      .post(url.type + "cp", formData)
      .then((res) => {
        setReload(!reload);
        setStateCP(!stateCP);
      })
      .catch((err) => {
        console.log(err.response.data.error);
      });
  };

  return (
    <div>
      {/*********************************    GESTION ADMINISTRATEUR ***********************************/}

      <div id="menu-gestion-secondaire">
        <button
          onClick={(e) => setValueGestion("BULLETINS PDF")}
          className={
            valueGestion === "BULLETINS PDF" ? "btn-gestion-active" : ""
          }
        >
          Bulletins PDF
        </button>
        <button
          onClick={(e) => setValueGestion("CARTES POSTALES")}
          className={
            valueGestion === "CARTES POSTALES" ? "btn-gestion-active" : ""
          }
        >
          Cartes Postales
        </button>
        <button
          onClick={(e) => setValueGestion("GRAVURES")}
          className={valueGestion === "GRAVURES" ? "btn-gestion-active" : ""}
        >
          Gravures
        </button>
      </div>

      {/*********************************    GESTION PDF ***********************************/}

      {/******************  AJOUT/SUPPRESSION PDF **************************/}

      {valueGestion === "BULLETINS PDF" && (
        <>
          {statePdf ? (
            <div id="askPdf">
              <button id="askAddPdf" onClick={() => setStatePdf(!statePdf)}>
                +{" "}
              </button>
              <button
                id="askDeletePdf"
                onClick={() => setStateDeletePdf(!stateDeletePdf)}
              >
                x
              </button>
            </div>
          ) : (
            <div id="gestion-pdf">
              <div id="zone-btn-close-addpdf">
                <button
                  id="btn-close-addpdf"
                  onClick={() => setStatePdf(!statePdf)}
                >
                  X
                </button>
              </div>

              <div className="info-pdf">
                <label htmlFor="pdflien"></label>
                <input
                  id="pdflien"
                  type="file"
                  name="pdflien"
                  accept=".pdf"
                  {...register("pdflien")}
                />
              </div>
              <div className="info-pdf">
                <label htmlFor="pdfname"></label>
                <input
                  id="pdfname"
                  type="text"
                  name="pdfname"
                  autoComplete="off"
                  {...register("pdfname")}
                  placeholder="Entrer nom pdf"
                />
              </div>
              <div className="info-pdf">
                <label htmlFor="pdfyear"></label>
                <input
                  id="pdfyear"
                  type="number"
                  name="pdfyear"
                  {...register("pdfyear")}
                  placeholder="Entrer année pdf"
                />
              </div>

              <div id="typepdf" className="info-pdf">
                {typePdf && (
                  <div>
                    <label htmlFor="pdfprix"></label>
                    <input
                      id="pdfprix"
                      placeholder="   Prix PDF ...€"
                      type="number"
                      name="pdfprix"
                      {...register("pdfprix")}
                    />
                  </div>
                )}
                <div className="pdftype">
                  <label htmlFor="pdftype">gratuit</label>

                  <input
                    id="pdftype"
                    type="checkbox"
                    onChange={() => {
                      setTypePdf(!typePdf);
                    }}
                    name="pdftype"
                  />
                </div>
              </div>

              <button
                id="btn-addpdf"
                type="submit"
                onClick={handleSubmit(onSubmit)}
              >
                Ajouter PDF
              </button>
            </div>
          )}
        </>
      )}

      {/******************  AFFICHAGE PDF **************************/}
      {valueGestion === "BULLETINS PDF" && (
        <div id="allpdf">
          <div id="pdfgratuit">
            <div id="categorie-pdf">
              <p>ANNEE</p>
              <p id="tableur-pdf">PDF GRATUITS</p>
              <p>PRIX</p>
            </div>

            {containPdf.map((pdf) => (
              <div>
                {pdf.pdfprix === 0 && (
                  <div className="pdfgratuit">
                    <p>{pdf.pdfyear}</p>
                    <a href={pdf.pdflien}>{pdf.pdfname}</a>
                    <p>-</p>
                    {stateDeletePdf && (
                      <button
                        id="supprimerpdf"
                        onClick={() => {
                          if (
                            window.confirm(
                              "Voulez-vous vraiment supprimer ce PDF ?"
                            )
                          ) {
                            deletePdf(pdf.id);
                          }
                        }}
                      >
                        X
                      </button>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>

          <div id="pdfpayant">
            <div id="categorie-pdf">
              <p>ANNEE</p>
              <p id="tableur-pdf">PDF PAYANTS</p>
              <p>PRIX</p>
            </div>

            {containPdf.map((pdf) => (
              <>
                {pdf.pdfprix > 0 && (
                  <div className="pdfpayant">
                    <p>{pdf.pdfyear}</p>
                    <a href={pdf.pdflien}>{pdf.pdfname}</a>
                    <p>{pdf.pdfprix} €</p>
                    {stateDeletePdf && (
                      <button
                        id="supprimerpdf"
                        onClick={() => {
                          if (
                            window.confirm(
                              "Voulez-vous vraiment supprimer ce PDF ?"
                            )
                          ) {
                            deletePdf(pdf.id);
                          }
                        }}
                      >
                        X
                      </button>
                    )}
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      )}

      {/*********************************    GESTION CARTES POSTALES ***********************************/}

      {/******************  AJOUT/SUPPRESSION CP **************************/}

      {valueGestion === "CARTES POSTALES" && (
        <>
          {stateCP ? (
            <div id="askCP">
              <button id="askAddCP" onClick={() => setStateCP(!stateCP)}>
                +
              </button>
              <button
                id="askDeleteCP"
                onClick={() => setStateDeleteCP(!stateDeleteCP)}
              >
                x
              </button>
            </div>
          ) : (
            <div id="gestion-cp">
              <div id="zone-btn-close-addcp">
                <button
                  id="btn-close-addcp"
                  onClick={() => setStateCP(!stateCP)}
                >
                  X
                </button>
              </div>

              <div className="info-cp">
                <label htmlFor="cplien"></label>
                <input
                  id="cplien"
                  type="file"
                  name="cplien"
                  {...register("cplien")}
                />
              </div>
              <div className="info-cp">
                <label htmlFor="cpname"></label>
                <input
                  id="cpname"
                  type="text"
                  name="cpname"
                  autoComplete="off"
                  {...register("cpname")}
                  placeholder="Entrer nom carte postale"
                />
              </div>
              <div>
                <label htmlFor="cpprix"></label>
                <input
                  id="cpprix"
                  placeholder="   Prix Carte Postale ...€"
                  type="number"
                  name="cpprix"
                  {...register("cpprix")}
                />
              </div>

              <button
                id="btn-addcp"
                type="submit"
                onClick={handleSubmit(onSubmitCp)}
              >
                Ajouter CARTE POSTALE
              </button>
            </div>
          )}
        </>
      )}

      {/******************  AFFICHAGE CP **************************/}

      {valueGestion === "CARTES POSTALES" && (
        <div id="allcp">
          <div id="cp">
            <div id="categorie-cp">
              <p id="tableur-cp">CARTE POSTALE</p>
              <p>PRIX</p>
            </div>
            {apercuCp && (
              <>
                <div id="specimen">
                  <div className="specimen">
                    <p>SEM MONTBELIARD SEM MONTBELIARD SEM MONTBELIARD</p>
                    <br />
                    <br />
                    <p>SEM MONTBELIARD SEM MONTBELIARD SEM MONTBELIARD</p>
                    <br />
                    <br />
                    <p>SEM MONTBELIARD SEM MONTBELIARD SEM MONTBELIARD</p>
                    <br />
                    <br />
                    <p>SEM MONTBELIARD SEM MONTBELIARD SEM MONTBELIARD</p>
                  </div>
                </div>
                <img
                  id="originale"
                  src={imageCp}
                  alt=""
                  onClick={() => setApercuCp(!apercuCp)}
                />
              </>
            )}
            {containCp.map((cp) => (
              <div>
                {cp.cpprix && (
                  <div className="cp">
                    <span
                      onClick={() => {
                        setApercuCp(!apercuCp);
                        setIdApercuCp(cp.id);
                      }}
                      // onMouseLeave={() => {
                      //   if (apercuCp) {
                      //     setApercuCp(!apercuCp);
                      //   }
                      // }}
                    >
                      {cp.cpname}
                    </span>

                    <p>{cp.cpprix} €</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      {valueGestion === "GRAVURES" && (
        <>
          <div>GRAVURES</div>
        </>
      )}
    </div>
  );
};

export default Gestion;
