import axios from "axios";
import Url from "./Url";
import React, { useEffect, useState } from "react";
const MessageAdhesion = (props) => {
  const url = <Url />;
  //   const d = new Date(props.showMessageAdhesion.createdAt);

  useEffect(() => {}, []);

  const [boxDelete, setBoxDelete] = useState("");
  const [stateBox, setStateBox] = useState("");
  const [x, setX] = useState(false);

  const resetBoxDelete = () => {
    setX(false);
  };

  const askDeleteUser = (id) => {
    setStateBox(id);
    setX(!x);

    setBoxDelete(
      <div className="boxDelContactMessage">
        <div>
          Confimer la suppression du message de :{" "}
          {props.showMessageAdhesion.firstname +
            " " +
            props.showMessageAdhesion.name}
        </div>
        <div className="choixDel">
          <p className="confirmDelete" onClick={() => deleteMessageContact(id)}>
            OUI
          </p>
          <p className="annuleDelete" onClick={() => resetBoxDelete()}>
            NON
          </p>
        </div>
      </div>
    );
  };

  const deleteMessageContact = (id) => {
    axios
      .delete(url.type + `adhesion/${id}`)
      .then(() => window.location.reload());
  };

  return (
    <li>
      <h3>
        Message de {props.showMessageAdhesion.firstname}{" "}
        {props.showMessageAdhesion.name}
      </h3>

      {/* <h4>
        Reçu le : {d.toLocaleDateString()} à {d.toLocaleTimeString()}
      </h4> */}

      <div className="messageContact-deleteMessageContact">
        <div className="infoMessageAdhesion">
          <div>
            <p>{"Nom : " + props.showMessageAdhesion.name}</p>
            <p>{"Prénom : " + props.showMessageAdhesion.firstname}</p>
            <p>{"Profession : " + props.showMessageAdhesion.profession}</p>
            <div className="adresseAdhesion">
              <p>{props.showMessageAdhesion.adresse}</p>
              <p>{props.showMessageAdhesion.codepostal}</p>
              <p>{props.showMessageAdhesion.ville}</p>
            </div>
          </div>
          <div>
            <p>{"Email : " + props.showMessageAdhesion.email}</p>
            <p>{"Tel : " + props.showMessageAdhesion.tel}</p>
          </div>
          <div>
            <p>{"Réglement : " + props.showMessageAdhesion.reglement}</p>
          </div>

          <br />
        </div>
        <button onClick={() => askDeleteUser(props.showMessageAdhesion.id)}>
          Supprimer
        </button>
        {props.showMessageAdhesion.id === stateBox && x && boxDelete}
      </div>
    </li>
  );
};

export default MessageAdhesion;
