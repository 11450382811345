import React, { useState } from "react";

const HomeContent = () => {
  const français = (
    <>
      <h4>Bienvenue sur le site de la Société d'Émulation de Montbéliard</h4>
      <p>
        La Société d'Émulation est une société savante née en 1851. Elle a pour
        objectif d'encourager, de propager le goût des Lettres, des Sciences et
        des Arts dans le cadre de l'ancienne principauté wurtembergoise de
        Montbéliard; petit pays situé dans la Porte de Bourgogne.
      </p>
    </>
  );
  const [intro, setIntro] = useState(français);

  const anglais = (
    <>
      <h4>
        Welcome to the website of « La Société d'Émulation de Montbéliard »
      </h4>
      <p>
        Société d’Émulation de Montbéliard – Welcome to our website The Société
        d’Émulation de Montbéliard is a learned society, founded in 1851. It
        aims to stimulate and expand the taste for letters, arts and sciences
        within the former principality of Montbéliard, a little county ruled by
        the House of Würtemberg and located on the edge of a plateau known as
        the Belfort Gap. (Traduction : Martin PETROWSKY)
      </p>
    </>
  );

  const allemand = (
    <>
      <h4>
        Willkommen auf der Homepage der « Société d'Émulation de Montbéliard »
        (Geschichtsverein)
      </h4>
      <p>
        Der Geschichtsverein von Montbéliard ist eine wissenschaftliche
        Gesellschaft, die 1851 gegründet wurde. Ihr Ziel ist die Förderung und
        die Verbreitung von Litératur, Wissenschaft und Kunst und dies im
        geographischen Rahmen der früheren würtembergischen Grafschat von
        Montbéliard (Mömpelgard), einem kleinen Land in der burgundischen
        Pforte.
      </p>
    </>
  );
  return (
    <main className="content">
      <div className="introStory">
        <div id="intro">
          <div className="drapeauLogo">
            <div className="drapeau">
              <img
                src="./images/drapeau-france-modified.png"
                alt="drapeauFrançais"
                onClick={() => setIntro(français)}
              />
              <img
                src="./images/drapeau-anglais-modified.png"
                alt="drapeauAnglais"
                onClick={() => setIntro(anglais)}
              />
              <img
                src="./images/allemagne-modified.png"
                alt="drapeauAllemand"
                onClick={() => setIntro(allemand)}
              />
            </div>

            <img id="logoSEM" src="./images/logo.png" alt="logoSEM" />
          </div>

          {intro}
        </div>

        <div id="historique">
          <div className="generalHistorique">
            <h2>Historique</h2>
            <p>
              Avec la participation de ses sociétaires, elle organise
              annuellement sept conférences, sur des thèmes propres au Pays de
              Montbéliard, et une journée d'étude qui sont publiées et relatées
              dans ses bulletins et mémoires. Elle édite également des ouvrages
              hors-série, dont un fut couronné par un prix de l'Institut des
              Sciences morales et politiques en 1994, et La Lettre, liens avec
              ses adhérents, qui fait le point deux fois par an sur la marche de
              l'association.
              <br />
              <br /> La Société d’Émulation est à l'origine des deux musées de
              la Ville de Montbéliard (musée du Château et musée d'Art et
              d'histoire). Les collections de sciences naturelles, d’archéologie
              d’art et d’histoire qu’elle a rassemblées ont fait l’objet de deux
              donations à la ville en 1960 et 1992. Elle continue à justifier
              ses buts patrimoniaux en faisant de fréquentes donations pour
              accroître les collections des musées. Sa bibliothèque, accessible
              au public, compte des ouvrages concernant Montbéliard et les
              publications de 85 sociétés savantes correspondantes. Yves
              Pradeilles
            </p>
          </div>
        </div>
      </div>

      <div id="administration">
        <h2>Bureau - Conseil d'administration</h2>
        <div id="adminGrid">
          <div className="generalA">
            <h3>I. Bureau : MM. : </h3>
            <div className="roles">
              <div className="presidence">
                <span>
                  <h4>Président : </h4>
                  André BOUVARD
                  <br />
                  professeur (E.R.), Montbéliard
                </span>
                <span>
                  <h4>Vice-président : </h4>
                  --- poste vacant ---
                </span>
              </div>
              <div className="secretariat">
                <span>
                  <h4>Secrétaire général : </h4>
                  Alain MERCIER
                  <br />
                  chirurgien-dentiste (E.R.), Montbéliard
                </span>
                <span>
                  <h4>Secrétaire général adjoint : </h4>
                  René VERMOT-DESROCHES
                  <br />
                  Dampierre-les-Bois
                </span>
              </div>
              <div className="tresorerie">
                <span>
                  <h4>Trésorière : </h4>
                  Sylvie LUCCHESI
                  <br />
                  cadre bancaire (E.R.), Bavans
                </span>
                <span>
                  <h4>Trésorière adjointe : </h4>
                  Maryse CHIPEAUX
                  <br />
                  médecin scolaire (E.R.), Montbéliard
                </span>
              </div>
              <div className="assBibl">
                <span>
                  <h4>Assesseurs : </h4>
                  Yves PRADEILLES
                  <br />
                  cadre supérieur (E.R.), Montbéliard
                  <br />
                  <br />
                  Christian TCHIRAKADZÉ
                  <br />
                  retraité, Blussangeaux
                </span>
                <span>
                  <h4>Bibliothécaire : </h4>
                  Denise HUGON
                  <br />
                  Montbéliard
                </span>
              </div>
            </div>
          </div>
          <div className="generalA">
            <h3>II. Conseil d'administration : MM. : </h3>
            <div className="adminadmin">
              <p>François BERNARDIN, technicien méthodes, Exincourt </p>
              <p>Claude CANARD, ouvrier qualifié, Chagey </p>
              <p>Claude CARDOT, ingénieur (E. R.), Voujeaucourt</p>
              <p>Pierre CROISSANT, ouvrier, Valentigney </p>
              <p> Vincent FOURNIER, professeur, Strasbourg </p>
              <p>Mathieu KALYNTSCHUK, professeur, Montenois </p>
              <p>Thierry MALVESY, conservateur de musée, Etupes </p>
              <p>Jacques MONAMY, professeur honoraire, Seloncourt</p>
              <p>
                Brigitte VION-DELPHIN, bibliothécaire (E. R.), cooptée,
                Montbéliard
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default HomeContent;
