import axios from "axios";
import Url from "../components/Url";
import { NavLink, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import Header from "../components/Header";
import Edit from "../components/Edit";
import { Editor } from "@tinymce/tinymce-react";
import Background from "../components/Background";
import { HashLink } from "react-router-hash-link";
import React, { useEffect, useRef, useState } from "react";

import { useForm } from "react-hook-form";

import { useNavigate } from "react-router-dom";
import NavConnexion from "../components/NavConnexion";
import Title from "../components/Title";

const Modify = () => {
  const url = <Url />;
  const { state } = useLocation();
  const [valeur, setValeur] = useState("");
  const [img, setImg] = useState("");
  const editorRef = useRef(null);
  const [cookies] = useCookies(["token", "isLog", "id"]);
  const [name, setName] = useState();
  const [contenu, setContenu] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const log = () => {
    if (editorRef.current) {
      console.log("tiny : " + editorRef.current.getContent());

      setContenu(editorRef.current.getContent());
    }
  };
  const navigate = useNavigate();

  //axios.defaults.headers.common["Authorization"] = "Bearer " + cookies.token;

  useEffect(() => {
    axios.get(url.type + "envoi/" + state).then((res) => {
      console.log(res.data.imgUrl);
      setImg(res.data.imgUrl);
      setValeur(res.data.contenu);
      setName(res.data.username);
    });
  }, []);

  const onSubmit = (donnees) => {
    if (donnees.imgUrl.length === 0) {
      axios
        .put(url.type + "envoi/sans/" + state, {
          contenu: contenu,
          id: cookies.id,
          username: name,
          imgUrl: null,
        })
        .then((res) => {
          navigate("/activity", { replace: true });
          // window.location.reload();
        })
        .catch((err) => {
          console.log(err.response.data.error);
        });
    } else {
      const formData = new FormData();
      formData.append("image", donnees.imgUrl[0]);
      formData.append("contenu", contenu);
      formData.append("id", cookies.id);
      formData.append("username", name);

      axios
        //.post("http://localhost:3000/api/envoi", {
        .put(url.type + "envoi/" + state, formData)
        .then((res) => {
          navigate("/activity", { replace: true });
          //window.location.reload();
        })
        .catch((err) => {
          console.log(err.response.data.error);
        });
    }
  };

  return (
    <div>
      <header>
        <div className="headerTwo">
          <NavLink to="/" className="back">
            <i class="fa-solid fa-arrow-left"></i>
          </NavLink>

          <span>
            <img src="./images/logo.png" alt="logoSEM" id="logoTwo" />
            Modifications
            <img src="./images/logo.png" alt="logoSEM" id="logoTwo" />
          </span>
        </div>
        <nav>
          <NavConnexion />
        </nav>
        <Background />
        <Title />
      </header>
      <div className="editeurNew">
        {/*<Edit valeur={valeur} />*/}
        <Editor
          id="myTiny"
          apiKey="3ajy5hpawccpmo32fz6gxagzsxxk2vh526cw0almcc0s5lsr"
          onInit={(evt, editor) => (editorRef.current = editor)}
          initialValue={valeur}
          init={{
            height: 300,
            //menubar: false,

            menubar: true,
            plugins: [
              "advlist",
              "autolink",
              "lists",
              "link",
              "image",
              "charmap",
              "preview",
              "anchor",
              "searchreplace",
              "visualblocks",
              "code",
              "fullscreen",
              "insertdatetime",
              "media",
              "table",
              "code",
              "help",
              "wordcount",
            ],
            toolbar:
              "undo redo | blocks | " +
              "bold italic forecolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help",

            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
          onKeyUp={log}
        />
        <div className="fileImg">
          <label htmlFor="fileImg">
            <i className="fa-sharp fa-solid fa-plus"></i>
            <span>Ajouter une image</span>
          </label>

          <input
            type="file"
            id="fileImg"
            name="imgUrl"
            {...register("imgUrl")}
          />
          <div id="imgModify">
            <p>Remplacer cette image ? : </p>
            <img src={img} alt="" />
          </div>
          <button type="submit" onClick={handleSubmit(onSubmit)}>
            Poster
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modify;
