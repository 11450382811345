import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import Perso from "./pages/Perso";
import { useCookies } from "react-cookie";
import axios from "axios";
import Activity from "./pages/Activity";
import Contact from "./pages/Contact";
import Adhesion from "./pages/Adhesion";
import Pays from "./pages/Pays";
import Modify from "./pages/Modify";
import Societes from "./pages/Societes";
import Membre from "./pages/Membre";
import Admin from "./pages/Admin";
import Url from "./components/Url";
import Commande from "./pages/Commande";
import Gestion from "./pages/Gestion";

const App = () => {
  const url = <Url />;
  const [cookies, setCookie] = useCookies(["token", "isLog", "id"]);
  const [isAdmin, setIsAdmin] = useState(false);

  axios.defaults.headers.common["Authorization"] = "Bearer " + cookies.token;

  useEffect(() => {
    axios.get(url.type + `auth/${cookies.id}`).then((res) => {
      setIsAdmin(res.data.isAdmin);
    });
  }, [cookies.id]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Home />} />
        <Route path="/activity" element={<Activity />} />
        {/*<Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />*/}
        <Route path="/commande" element={<Commande />} />
        <Route path="/pays" element={<Pays />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/adhesion" element={<Adhesion />} />
        <Route path="/modify" element={<Modify />} />
        <Route path="/societes" element={<Societes />} />
        {cookies.isLog && cookies.token && (
          <Route path="/membre" element={<Membre />} />
        )}
        {isAdmin && <Route path="/admin" element={<Admin />} />}
        {cookies.isLog && cookies.token && (
          <Route path="/parametres" element={<Perso />} />
        )}
      </Routes>
    </BrowserRouter>
  );
};

export default App;
