import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

/// test => mess dans BDD /////

import axios from "axios";
import { useForm } from "react-hook-form";
import Url from "../components/Url";

export const FormContact = () => {
  const url = <Url />;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (donnees) => {
    // const formData = new FormData();
    // formData.append("name", donnees.name);
    // formData.append("firstname", donnees.firstname);
    // formData.append("email", donnees.email);
    // formData.append("tel", donnees.tel);
    // formData.append("sujet", donnees.sujet);
    // formData.append("message", donnees.message);

    axios
      .post(url.type + "contact", {
        name: donnees.name,
        firstname: donnees.firstname,
        email: donnees.email,
        tel: donnees.tel,
        codepostal: donnees.codepostal,
        adresse: donnees.adresse,
        ville: donnees.ville,
        profession: donnees.profession,
        sujet: donnees.sujet,
        message: donnees.message,
        state: false,
      })
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err.response.data.error);
      });
  };

  /* const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_thc1ckn",
        "template_uqbkcmk",
        form.current,
        "LM7mhaE6BvcZWqij4"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };*/

  return (
    <form /*ref={form} onSubmit={sendEmail} */>
      <div className="infos">
        <label>
          <input
            {...register("name")}
            type="text"
            name="name"
            placeholder="Nom : "
            required
            autoComplete="off"
          />
        </label>
        <label>
          <input
            {...register("firstname")}
            type="text"
            name="firstname"
            placeholder="Prénom : "
            required
            autoComplete="off"
          />
        </label>
        <label>
          <input
            {...register("email")}
            type="text"
            name="email"
            placeholder="Email : xxx@gmail.com"
            required
            autoComplete="off"
          />
        </label>
        <label>
          <input
            {...register("tel")}
            type="text"
            name="tel"
            placeholder="Tél : 00 00 00 00 00"
            required
            autoComplete="off"
          />
        </label>
        <label>
          <input
            {...register("codepostal")}
            type="number"
            name="codepostal"
            placeholder="code postal : xxxxx"
            required
            autoComplete="off"
          />
        </label>
        <label>
          <input
            {...register("adresse")}
            type="text"
            name="adresse"
            placeholder="adresse : x rue ...."
            required
            autoComplete="off"
          />
        </label>
        <label>
          <input
            {...register("ville")}
            type="text"
            name="ville"
            placeholder="Ville"
            required
            autoComplete="off"
          />
        </label>
        <label>
          <input
            {...register("profession")}
            type="text"
            name="profession"
            placeholder="Profession"
            required
            autoComplete="off"
          />
        </label>
      </div>

      <label id="sujet">
        <input
          {...register("sujet")}
          type="text"
          name="sujet"
          placeholder="Sujet : ............"
        />
      </label>
      <label id="area">
        <textarea
          // cols="60"
          name="message"
          {...register("message")}
          rows="10"
          type="text"
          placeholder="Dites quelque chose ..."
        />
      </label>
      {/* <label>
        <input type="submit" onClick={handleSubmit(onSubmit)} />
      </label> */}
      <button id="ajoutpdf" type="submit" onClick={handleSubmit(onSubmit)}>
        Envoyer
      </button>
    </form>
  );
};
