import React, { useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";
import { NavLink } from "react-router-dom";

const NavActivity = () => {
  const [show, handleShow] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 150) {
        handleShow(true);
      } else handleShow(false);
    });
  }, []);

  return (
    <>
      {show && (
        <div id="navActivity">
          <span>
            <div id="pActiv">
              <p>
                ACTIVITES <i className="fa-sharp fa-solid fa-sort-down"></i>
              </p>
              <div className="borderActiv"></div>
            </div>
            <div className="ddd" id="ddd">
              <HashLink smooth to="/activity#communication">
                Communication
              </HashLink>
              <HashLink smooth to="/activity#journeeEtudes">
                Journée d'études
              </HashLink>
              <HashLink smooth to="/activity#chroniques">
                Chroniques
              </HashLink>
              <HashLink smooth to="/activity#achatsMusee">
                Achats pour les musées
              </HashLink>
              <HashLink smooth to="/activity#museeVirtuel">
                Musée virtuel
              </HashLink>
              <HashLink smooth to="/activity#nosParticipations">
                Nos participations
              </HashLink>
            </div>
          </span>
          <img src="./images/logo.png" alt="logoSEM" />
        </div>
      )}
    </>
  );
};

export default NavActivity;
