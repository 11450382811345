import React, { useEffect, useState } from "react";
import axios from "axios";
import Url from "./Url";

const StateCommande = (props) => {
  const url = <Url />;

  const [zoneState, setZoneState] = useState(
    <button
      onClick={() => {
        axios
          .put(url.type + `pdfUser/${props.cmd.id}`, {
            state: true,
          })
          .then(setZoneState(<p id="mess-cmd-valide">Commande Validée</p>));
      }}
    >
      Valider commande
    </button>
  );

  useEffect(() => {
    axios.get(url.type + "pdfUser");
  }, [props.cmd.state]);

  return (
    <li id="ligneCommande">
      <p> {props.cmd.username}</p>
      <p>{props.cmd.pdfname}</p>

      {!props.cmd.state ? (
        zoneState
      ) : (
        <p id="mess-cmd-valide">Commande Validée</p>
      )}
      {/* {props.cmd.state ? (
        <p id="mess-cmd-valide">Commande Validée</p>
      ) : (
        <button
          onClick={() => {
            axios.put(url.type + `pdfUser/${props.cmd.id}`, {
              state: true,
            });
          }}
        >
          Valider commande
        </button>
      )} */}
    </li>
  );
};

export default StateCommande;
