import React from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import Url from "../components/Url";

const FormAdhesion = () => {
  const url = <Url />;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (donnees) => {
    axios
      .post(url.type + "adhesion", {
        name: donnees.name,
        firstname: donnees.firstname,
        email: donnees.email,
        tel: donnees.tel,
        codepostal: donnees.codepostal,
        adresse: donnees.adresse,
        ville: donnees.ville,
        profession: donnees.profession,
        reglement: donnees.reglement,
        state: false,
      })
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err.response.data.error);
      });
  };

  return (
    <form>
      <div className="infos">
        <label>
          <input
            {...register("name")}
            type="text"
            name="name"
            placeholder="Nom : "
            required
            autoComplete="off"
          />
        </label>
        <label>
          <input
            {...register("firstname")}
            type="text"
            name="firstname"
            placeholder="Prénom : "
            required
            autoComplete="off"
          />
        </label>
        <label>
          <input
            {...register("email")}
            type="text"
            name="email"
            placeholder="Email : xxx@gmail.com"
            required
            autoComplete="off"
          />
        </label>
        <label>
          <input
            {...register("tel")}
            type="text"
            name="tel"
            placeholder="Tél : 00 00 00 00 00"
            required
            autoComplete="off"
          />
        </label>
        <label>
          <input
            {...register("codepostal")}
            type="number"
            name="codepostal"
            placeholder="code postal : xxxxx"
            required
            autoComplete="off"
          />
        </label>
        <label>
          <input
            {...register("adresse")}
            type="text"
            name="adresse"
            placeholder="adresse : x rue ...."
            required
            autoComplete="off"
          />
        </label>
        <label>
          <input
            {...register("ville")}
            type="text"
            name="ville"
            placeholder="Ville"
            required
            autoComplete="off"
          />
        </label>
        <label>
          <input
            {...register("profession")}
            type="text"
            name="profession"
            placeholder="Profession"
            required
            autoComplete="off"
          />
        </label>
      </div>
      <label htmlFor="reglement">
        Moyen de paiement :
        <select name="reglement" id="reglement" {...register("reglement")}>
          <option value="cheque" type="text">
            chèque
          </option>
          <option value="virement" type="text">
            virement
          </option>
        </select>
      </label>

      <button type="submit" onClick={handleSubmit(onSubmit)}>
        Demande d'adhésion
      </button>
    </form>
  );
};

export default FormAdhesion;
