import axios from "axios";
import { set, useForm } from "react-hook-form";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Url from "./Url";

const Login = ({ stateLog }) => {
  const url = <Url />;
  const [cookies, setCookie] = useCookies(["token", "isLog", "id"]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = (donnees) => {
    axios

      // .post("http://localhost:3000/api/auth/login", donnees)
      .post(url.type + "auth/login", donnees)
      .then((res) => {
        const TOKEN = res.data.token;
        const ISLOG = res.data.isLogged;
        const ID = res.data.id;
        setCookie("token", TOKEN, { path: "/", sameSite: "strict" });
        setCookie("isLog", ISLOG, { path: "/", sameSite: "strict" });
        setCookie("id", ID, { path: "/", sameSite: "strict" });
        setCookie("theme", "original", { path: "/", sameSite: "strict" });

        console.log("vos infos : " + res.data);
        function x() {
          res.data.id === 1
            ? navigate("/parametres", { replace: true })
            : navigate("/membre", { replace: true });
        }
        setTimeout(x, 1000);
      })
      .catch((err) => {});
  };

  const y = "formLoginShow";
  const [z, setZ] = useState("formLoginStart");

  useEffect(() => {
    if (stateLog) setZ("formLoginHide");
  }, [stateLog]);

  return (
    <div className="formS">
      <>
        <form
          id="formLogin"
          className={stateLog ? y : z}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <label htmlFor="username">
              IDENTIFIANT :
              <input
                autoCapitalize="none"
                name="username"
                type="text"
                placeholder="ID"
                autoComplete="off"
                {...register("username", { required: true, maxLength: 80 })}
              />
            </label>
            <label htmlFor="password">
              MOT DE PASSE :{" "}
              <input
                autoCapitalize="none"
                name="password"
                type="password"
                placeholder="Password"
                {...register("password", { required: true, min: 5 })}
              />
            </label>
          </div>

          <button type="submit" id="loginBtn">
            Connexion
          </button>
        </form>
      </>
    </div>
  );
};

export default Login;
