import axios from "axios";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { NavLink } from "react-router-dom";
import Background from "../components/Background";
import NavConnexion from "../components/NavConnexion";
import Title from "../components/Title";
import Up from "../components/Up";
import Url from "../components/Url";

const Admin = () => {
  const url = <Url />;
  const [cookies, setCookies] = useCookies(["token", "isLog", "id"]);
  const [name, setName] = useState();
  const [isAdmin, setIsAdmin] = useState(false);
  const [user, setUser] = useState();

  useEffect(() => {
    axios.get(url.type + `auth/`).then((res) => {
      setUser(res.data);
    });
  }, [cookies.id]);

  useEffect(() => {
    axios.get(url.type + `auth/${cookies.id}`).then((res) => {
      setIsAdmin(res.data.isAdmin);
    });
  }, [cookies.id]);

  const [boxDelete, setBoxDelete] = useState("");
  const [stateBox, setStateBox] = useState("");
  const [x, setX] = useState(false);

  const resetBoxDelete = () => {
    setX(false);
  };

  const askDeleteUser = (id) => {
    setStateBox(id);
    setX(!x);
    setBoxDelete(
      <div className="boxDel">
        <div>Confimer la suppression de l'utilisateur : {id}</div>
        <div className="choixDel">
          <p className="confirmDelete" onClick={() => deleteUser(id)}>
            OUI
          </p>
          <p className="annuleDelete" onClick={() => resetBoxDelete()}>
            NON
          </p>
        </div>
      </div>
    );
  };

  const deleteUser = (id) => {
    axios.delete(url.type + `auth/${id}`).then(() => window.location.reload());
  };

  return (
    <div>
      <Up />
      <header>
        <div className="headerTwo">
          <NavLink to="/" className="back">
            <i className="fa-solid fa-arrow-left"></i>
          </NavLink>
        </div>
        <nav>
          <NavConnexion />
        </nav>
        <Background />
      </header>

      <div className="user">
        <h3>Infos membres</h3>
        <ul id="ulUser">
          {user &&
            user.map((us, index) => (
              <li key={index}>
                {!us.isAdmin && (
                  <div className="tableau-admin">
                    {us.avatar ? (
                      <img src={us.avatar} alt="" />
                    ) : (
                      <img src="./images/avatarneutre.png" alt="" />
                    )}

                    <div className="tableau-name-firstname">
                      <span>{us.username}</span>
                      <br />
                      <span>
                        {us.firstname} {us.name}
                      </span>
                      <br />
                      <span>{us.profession}</span>
                    </div>

                    <div className="tableau-email-tel">
                      <br />
                      <span>{us.email}</span>
                      <span>{us.tel}</span>
                      <br />
                      <div className="tableau-adresse">
                        <span> {us.adresse} </span>
                        <span>{us.codepostal + " " + us.ville} </span>
                        <br />
                      </div>
                    </div>

                    <i
                      id="deleteUser"
                      className="fa-solid fa-xmark"
                      onClick={() => askDeleteUser(us.id)}
                    ></i>
                    {us.id === stateBox && x && boxDelete}
                  </div>
                )}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default Admin;
