import React from "react";

const Footer = () => {
  return (
    <footer>
      <div className="footPosition">
        <div className="pFooter">
          <div className="reseau">
            <a href="https://www.facebook.com/people/Soci%C3%A9t%C3%A9-dEmulation-de-Montb%C3%A9liard/100064976070756/">
              <i class="fa-brands fa-square-facebook"></i>
            </a>
            <a href="#">
              <i class="fa-brands fa-instagram"></i>
            </a>
            <a href="#">
              <i class="fa-brands fa-square-twitter"></i>
            </a>
          </div>
          <p>Association loi 1901 - Reconnue d'utilité publique</p>
          <p>Hôtel Beurnier-Rossel</p>
          <p>BP 251 25204 Montbéliard Cedex </p>
          <p>(Accès par le 1A rue Cuvier)</p>
          <p>Tél/Fax : 03 81 91 23 91</p>
          <p>E-mail : sem.montbeliard@wanadoo.fr</p>
          <p>Permanences : Mercredi 14h30 - 16h30</p>
          <span>Site réalisé par mb-dev</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
