import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useForm } from "react-hook-form";

//import { useNavigate } from "react-router-dom";
import Url from "./Url";

const Edit = ({ valeur }) => {
  const url = <Url />;

  const editorRef = useRef(null);
  const [cookies] = useCookies(["token", "isLog", "id"]);
  const [name, setName] = useState();
  const [contenu, setContenu] = useState();

  const [img, setImg] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const log = () => {
    if (editorRef.current) {
      console.log("tiny : " + editorRef.current.getContent());

      setContenu(editorRef.current.getContent());
    }
  };
  //const navigate = useNavigate();
  const onSubmit = (donnees) => {
    if (donnees.imgUrl.length === 0) {
      axios
        // .post(url.type + "envoi/sans", {
        .post(url.type + "envoi/sans", {
          contenu: contenu,
          categorie: donnees.categorie,
          id: cookies.id,
          username: name,
          imgUrl: null,
        })
        .then((res) => {
          // navigate("/activity", { replace: true });
          window.location.reload();
        })
        .catch((err) => {
          console.log(err.response.data.error);
        });
    } else {
      const formData = new FormData();
      formData.append("image", donnees.imgUrl[0]);
      formData.append("contenu", contenu);
      formData.append("categorie", donnees.categorie);
      formData.append("id", cookies.id);
      formData.append("username", name);

      axios
        //.post("http://localhost:3000/api/envoi", {
        .post(url.type + "envoi", formData)
        .then((res) => {
          // navigate("/activity", { replace: true });
          window.location.reload();
        })
        .catch((err) => {
          console.log(err.response.data.error);
        });
    }
  };

  useEffect(() => {
    axios
      //.get(`http://localhost:3000/api/auth/${cookies.id}`).then((res) => {
      .get(url.type + `auth/${cookies.id}`)
      .then((res) => {
        setName(res.data.username);
      });
  }, [cookies.id]);

  return (
    <>
      <Editor
        id="myTiny"
        apiKey="3ajy5hpawccpmo32fz6gxagzsxxk2vh526cw0almcc0s5lsr"
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={valeur}
        init={{
          height: 500,

          //menubar: false,

          menubar: true,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "code",
            "help",
            "wordcount",
          ],
          toolbar:
            "undo redo | blocks | " +
            "bold italic forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",

          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
        onKeyUp={log}
      />
      <div className="fileImg">
        <label htmlFor="fileImg">
          <i className="fa-sharp fa-solid fa-plus"></i>
          <span>Ajouter une image</span>
        </label>

        <input
          type="file"
          id="fileImg"
          name="imgUrl"
          {...register("imgUrl")}
          onMouseOver={(e) => {
            console.log(e.target.value);
            setImg(e.target.value);
          }}
        />
        <select
          name="choix"
          id="choix"
          {...register("categorie", { required: true, min: 50 })}
        >
          <option value="">-- choisir une catégorie --</option>
          <option value="communication">communication</option>
          <option value="journeeEtudes">journée d'études</option>
          <option value="chronique">chroniques</option>
          <option value="achatMusee">achats pour les musées</option>
          <option value="museeVirtuel">musée virtuel</option>
          <option value="nosParticipations">nos participations</option>
        </select>

        <button type="submit" onClick={handleSubmit(onSubmit)}>
          Poster
        </button>
      </div>
    </>
  );
};

export default Edit;
