import React from "react";
import Header from "../components/Header";
import Up from "../components/Up";
import axios from "axios";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import Edit from "../components/Edit";
import Footer from "../components/Footer";
import Url from "../components/Url";
import NavActivity from "../components/NavActivity";
import Card from "../components/Card";

const Activity = () => {
  const url = <Url />;
  const [cookies, setCookies] = useCookies(["token", "isLog", "id"]);

  const [name, setName] = useState();
  const [isAdmin, setIsAdmin] = useState(false);

  const [contain, setContain] = useState([]);

  useEffect(() => {
    axios.get(url.type + `auth/${cookies.id}`).then((res) => {
      setName(res.data.username);
      setIsAdmin(res.data.isAdmin);
    });
    axios.get(url.type + "envoi").then((res) => {
      setContain(res.data);
    });
  }, [cookies.id]);

  return (
    <div id="communication">
      <NavActivity />
      <Up />

      <Header />

      <div className="activityContain">
        {isAdmin && (
          <>
            <h2 className="communication">Edition</h2>
            <div className="editeurNew">
              <Edit />
            </div>
          </>
        )}
        <h2 className="communication important">Communication</h2>
        <ul id="ulCom">
          {contain
            .filter((show) => show.categorie.includes("communication"))
            .map((show, index) => (
              <Card show={show} index={index} />
            ))}
        </ul>
        <div id="journeeEtudes">
          <h2 className="important">Journees d'études</h2>
          <ul id="ulCom">
            {contain
              .filter((show) => show.categorie.includes("journeeEtude"))
              .map((show, index) => (
                <Card show={show} index={index.toString()} key={index} />
              ))}
          </ul>
        </div>
        <div id="chroniques">
          <h2 className="important">Chroniques</h2>
          <ul id="ulCom">
            {contain
              .filter((show) => show.categorie.includes("chronique"))
              .map((show, index) => (
                <Card show={show} index={index} />
              ))}
          </ul>
        </div>

        <div id="achatsMusee">
          <h2 className="important">Achats pour les musées</h2>
          <ul id="ulCom">
            {contain
              .filter((show) => show.categorie.includes("achatMusee"))
              .map((show, index) => (
                <Card show={show} index={index} />
              ))}
          </ul>
        </div>

        <div id="museeVirtuel">
          <h2 className="important">Musée virtuel</h2>
          <ul id="ulCom">
            {contain
              .filter((show) => show.categorie.includes("museeVirtuel"))
              .map((show, index) => (
                <Card show={show} index={index} />
              ))}
          </ul>
        </div>
        <div id="nosParticipations">
          <h2 className="important">Nos participations</h2>
          <ul id="ulCom">
            {contain
              .filter((show) => show.categorie.includes("nosParticipations"))
              .map((show, index) => (
                <Card show={show} index={index} />
              ))}
          </ul>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Activity;
