import React from "react";

const Title = () => {
  return (
    <div className="title">
      <h1>SEM</h1>
      <h2>
        Société <br /> d'Émulation <br />
        de Montbéliard
      </h2>
      <div className="subTitle">
        <h3 id="archeologie">Archéologie</h3>
        <h3 id="histoire">Histoire</h3>
        <h3 id="lettres">Lettres</h3>
        <h3 id="art">Art</h3>
        <h3 id="sciences">Sciences</h3>
      </div>
    </div>
  );
};

export default Title;
