import React from "react";

import Footer from "../components/Footer";
import { FormContact } from "../components/FormContact";
import Header from "../components/Header";
import Up from "../components/Up";

const Contact = () => {
  return (
    <div>
      <Up />
      <Header />
      <div className="contact">
        <FormContact />
        <div className="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10779.908705733045!2d6.7976028!3d47.5098357!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb5214d89f25dd226!2sSoc%20D%20Emulation%20Montbeliard!5e0!3m2!1sfr!2sfr!4v1675328610590!5m2!1sfr!2sfr"
            width="600"
            height="450"
            style={{ border: 0 }}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="map"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
