import React from "react";
import Up from "../components/Up";
import { useCookies } from "react-cookie";
import axios from "axios";
import { useState, useEffect } from "react";
import Url from "../components/Url";
import Background from "../components/Background";
import { NavLink } from "react-router-dom";
import NavConnexion from "../components/NavConnexion";
import Title from "../components/Title";
import Footer from "../components/Footer";

const Membre = () => {
  const url = <Url />;
  //const url2 = "http://docs.google.com/gview?url=";
  const [cookies, setCookie] = useCookies(["token", "isLog", "id"]);
  const [userData, setUserData] = useState();
  const [containPdf, setContainPdf] = useState([]);
  const [pdfUser, setPdfUser] = useState([]);

  const [cmdWait, setCmdWait] = useState([]);

  const [name, setName] = useState();
  const [firstname, setFirstname] = useState();

  useEffect(() => {
    axios
      .get(url.type + "pdfUser")
      .then((res) => setPdfUser(res.data))
      .then();
  }, []);
  useEffect(() => {
    setCmdWait(
      pdfUser.filter((x) => x.username === userData && x.state === false).length
    );
  });

  useEffect(() => {
    axios.get(url.type + `auth/${cookies.id}`).then((res) => {
      setUserData(res.data.username);
      setName(res.data.name);
      setFirstname(res.data.firstname);
    });
    axios.get(url.type + "pdf").then((res) => setContainPdf(res.data));
  }, [cookies.id]);

  const [idCmdPdf, setIdCmdPdf] = useState();
  const [boxCmdPdf, setBoxCmdPdf] = useState("");
  const [x, setX] = useState(false);

  const resetBoxDelete = () => {
    setX(false);
  };

  const askCmdPdf = (lien, name) => {
    setIdCmdPdf(lien);
    setX(!x);
    setBoxCmdPdf(
      <div className="box-cmd-pdf">
        <div>Souaitez-vous commander le PDF : {name}</div>
        <div className="yesno-cmd-pdf">
          <button
            className="yes"
            onClick={() =>
              setBoxCmdPdf(
                <div className="box-cmd-pdf">
                  <p>
                    Veuillez faire un virement à : <br /> <br />
                    [RIB de la SEM], et vous aurez accès au PDF sous 2 jours
                    ouvrés.
                  </p>
                  <div className="yesno-cmd-pdf">
                    <button
                      id="validationPaiementPDF"
                      onClick={() => {
                        axios
                          .post(url.type + "pdfUser", {
                            pdfname: name,
                            pdflien: lien,
                            username: userData,
                          })
                          .then(() => window.location.reload());
                      }}
                    >
                      Cliquez ici <br /> une fois le paiement effectué
                    </button>
                    <button
                      id="refusPaiementPDF"
                      onClick={() => resetBoxDelete()}
                    >
                      Annuler la commande du PDF
                    </button>
                  </div>
                </div>
              )
            }
          >
            OUI
          </button>
          <button className="no" onClick={() => resetBoxDelete()}>
            NON
          </button>
        </div>
      </div>
    );
  };

  return (
    <div id="pageMembre">
      <Up />
      <Background />
      <header>
        <div className="headerTwo">
          <NavLink to="/" className="back">
            <i className="fa-solid fa-arrow-left"></i>
          </NavLink>

          <span>
            {/* <img src="./images/logo.png" alt="logoSEM" id="logoTwo" /> */}
            Bonjour {firstname}{" "}
            {/* <img src="./images/logo.png" alt="logoSEM" id="logoTwo" /> */}
          </span>
        </div>
        <nav>
          <NavConnexion />
        </nav>
      </header>
      <div id="menu-membre">
        <button>pdf</button>
        <button>cp</button>
        <button>gravure</button>
        <i class="fa-brands fa-shopify"></i>
        <span id="cmd-wait">{cmdWait}</span>
      </div>

      <div id="showAllpdf">
        <div id="allpdf">
          <div id="pdfgratuit">
            <div id="categorie-pdf">
              <p>ANNEE</p>
              <p id="tableur-pdf">PDF GRATUITS</p>
              <p>PRIX</p>
            </div>

            {containPdf.map((pdf) => (
              <div>
                {pdf.pdfprix === 0 && (
                  <div className="pdfgratuit">
                    <p>{pdf.pdfyear}</p>

                    <a
                      href={pdf.pdflien}
                      embedded
                      type="application/pdf"
                      target="_self"
                    >
                      {pdf.pdfname}
                    </a>
                    <p>-</p>
                  </div>
                )}
              </div>
            ))}
          </div>

          <div id="pdfpayant">
            <div id="categorie-pdf">
              <p>ANNEE</p>
              <p id="tableur-pdf">PDF PAYANTS</p>
              <p>PRIX</p>
            </div>

            {containPdf.map((pdf) => (
              <>
                {pdf.pdfprix > 0 && (
                  <div className="pdfpayant">
                    <p>{pdf.pdfyear}</p>

                    <p
                      id="a"
                      onClick={() => {
                        askCmdPdf(pdf.pdflien, pdf.pdfname);
                      }}
                    >
                      {pdf.pdfname}
                      {pdfUser.map(
                        (data) =>
                          pdf.pdfname === data.pdfname &&
                          data.username === userData &&
                          data.state && <span id="b"> - pdf possédé -</span>
                      )}
                      {pdfUser.map(
                        (data) =>
                          pdf.pdfname === data.pdfname &&
                          data.username === userData &&
                          !data.state && (
                            <span id="c"> - commande en cours -</span>
                          )
                      )}
                    </p>

                    <p>{pdf.pdfprix} €</p>
                  </div>
                )}
                <div className="zone-box-cmd-pdf">
                  {pdf.pdflien === idCmdPdf && x && boxCmdPdf}
                </div>
              </>
            ))}
          </div>
          <div id="pdfpossede">
            <div id="categorie-pdf-possede">
              <p className="pdf-possede">MES PDF</p>
            </div>
            {pdfUser.map(
              (data) =>
                data.username === userData &&
                data.state && (
                  <div className="lien-achete">
                    <a
                      href={data.pdflien}
                      id="lien-pdf-possede"
                      type="application/pdf"
                      embedded
                      target="_self"
                    >
                      {data.pdfname}
                    </a>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Membre;
